import cn from 'classnames';
import * as React from 'react';
import { Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { routes } from '../../constants/routes';
import useScrollPosition from '../../hooks/useScrollPosition';
import useWindowSize from '../../hooks/useWindowSize';
import { selectFlowSteps, selectOrgLogo } from '../../store/selectors/derived';

import { selectBaseColor } from '../../store/selectors/init';
import { SendBoxModeEnum } from '../../types/ui';
import { isLightColor } from '../../util/ui';
import BrandLogo from '../BrandLogo/BrandLogo';
import Greeting from '../Greeting/Greeting';
import SendBox from '../SendBox/SendBox';
import Stepper from '../Stepper/Stepper';

import styles from './Header.module.scss';

export interface IHeaderProps {
  className?: string;
}

const Header = ({ className }: IHeaderProps) => {
  const { pathname } = useLocation();
  const { mobile, medium } = useWindowSize();
  const { scrollY } = useScrollPosition();

  const steps = useSelector(selectFlowSteps(pathname));

  const orgLogo = useSelector(selectOrgLogo);
  const backgroundColor = useSelector(selectBaseColor);

  const dynamicStepperRef = React.useRef<HTMLOListElement | null>(null);
  const staticStepperRef = React.useRef<HTMLOListElement | null>(null);
  const logoContainerRef = React.useRef<HTMLDivElement | null>(null);

  const logoContainerHeight = logoContainerRef.current?.offsetHeight || 0;
  const staticStepperHeight = staticStepperRef?.current?.offsetHeight || 0;
  const dynamicStepperPosition = dynamicStepperRef.current?.getBoundingClientRect();
  const staticStepperPosition = staticStepperRef.current?.getBoundingClientRect();
  const [stepperInitialPosition, setStepperInitialPosition] = React.useState<number>(0);

  const shouldDisplayStepper = React.useMemo(() => steps.length && steps.length > 1, [steps]);
  const shouldDisplayStepperInNavbar = React.useMemo(() => {
    return (
      mobile &&
      shouldDisplayStepper &&
      staticStepperPosition &&
      staticStepperPosition?.top < staticStepperHeight * 2 + logoContainerHeight
    );
  }, [mobile, shouldDisplayStepper, scrollY, stepperInitialPosition]);

  const isBackgroundLight = React.useMemo(() => {
    return isLightColor(backgroundColor);
  }, [backgroundColor]);

  const LogoContainer = React.useMemo(
    () =>
      ({ style }: { style?: React.CSSProperties }) => {
        return (
          <div className={styles.logoContainer} style={style}>
            <BrandLogo image={orgLogo} />
          </div>
        );
      },
    [orgLogo, backgroundColor],
  );

  const sendBoxMode = React.useMemo(() => {
    return pathname === routes.address ? SendBoxModeEnum.Closed : SendBoxModeEnum.Open;
  }, [pathname]);

  const stepperMarkup = React.useCallback(
    (ref?: React.Ref<HTMLOListElement>) => {
      return (
        <Row className={styles.breadcrumbsContainer}>
          <Stepper
            active={pathname}
            className={styles.stepperContainer}
            labelClassName={cn(styles.stepperLabel, isBackgroundLight ? styles.dark : '')}
            steps={steps}
            ref={ref}
          />
        </Row>
      );
    },
    [steps, isBackgroundLight, pathname],
  );

  React.useEffect(() => {
    if (!scrollY && dynamicStepperPosition?.top && stepperInitialPosition !== scrollY) {
      return setStepperInitialPosition(dynamicStepperPosition?.top);
    }
  }, [dynamicStepperPosition, scrollY, stepperInitialPosition]);

  return (
    <React.Fragment>
      {mobile && (
        <Container
          fluid
          style={{
            backgroundColor,
          }}
          className={cn(styles.sticky)}
        >
          <Container>
            <LogoContainer style={{ backgroundColor }} />
            {shouldDisplayStepperInNavbar && stepperMarkup(dynamicStepperRef)}
          </Container>
        </Container>
      )}
      <div className={cn(styles.headerWrapper, className)} style={{ backgroundColor }}>
        <div className={styles.wave} />
        <Container className={styles.content}>
          {!mobile && <LogoContainer />}
          <div className={styles.headingContainer}>
            <Greeting className={cn(styles.greeting, { [styles.lightBackground]: isBackgroundLight })} />
            {!(mobile || medium) && <SendBox mode={sendBoxMode} className={styles.sendBox} logo={orgLogo} />}
          </div>
          {shouldDisplayStepper && stepperMarkup(staticStepperRef)}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Header;
