import tinycolor from 'tinycolor2';

export const isLightColor = (color: string | undefined) => {
  const backgroundColor = tinycolor(color);
  return backgroundColor.isLight();
};

export const isBrightColor = (color?: string) => {
  const validColor = tinycolor(color);
  return validColor ? validColor.getBrightness() > 190 : false;
};
