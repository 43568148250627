import cn from 'classnames';
import * as React from 'react';

import CustomizableIcon from '../../../assets/icon-customizable.svg';
import { CUSTOMIZABLE_ITEM_INDICATOR_HINT_TEXT } from '../../../constants/ui';

import styles from './CustomizableItemIndicator.module.scss';

export interface ICustomizableItemIndicatorProps {
  className?: string;
  title?: string;
}

const CustomizableItemIndicator: React.FC<ICustomizableItemIndicatorProps> = ({
  className,
  title = CUSTOMIZABLE_ITEM_INDICATOR_HINT_TEXT,
}) => {
  return (
    <span
      className={cn(styles.icon, className)}
      style={{ backgroundImage: `url("${CustomizableIcon}")` }}
      title={title}
    />
  );
};

export default CustomizableItemIndicator;
