import { ContentTypes, IContentTypeParseResult } from '../types/http';

export const noContentTypeParseResult: IContentTypeParseResult = {
  type: 'no-content',
  subtype: 'no-content',
  rest: 'no-content',
};

export const contentTypeRegex = /([a-z]+)\/([a-z-]+)(?:;\s*(.+))?/i;

export const contentTypes: ContentTypes[] = ['text', 'multipart', 'message', 'image', 'audio', 'video', 'application'];
