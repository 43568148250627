import cn from 'classnames';
import { FormikProps } from 'formik';
import * as React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { RECEIVER_EMAIL, RECEIVER_FIRST_NAME, RECEIVER_LAST_NAME, RECEIVER_PHONE } from '../../constants/shipping';
import { IDigitalReceiverInfo } from '../../types/api';
import Input from '../Input/Input';

import styles from './Forms.module.scss';

interface IProps {
  className?: string;
  form: FormikProps<Partial<IDigitalReceiverInfo>>;
  title?: string;
}

const FormDigital = ({ className, form, title }: IProps) => {
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value && Object.keys(form.errors).length) {
        form.setTouched({});
        form.setErrors({});
      }
      form.handleChange(e);
    },
    [form.handleChange, form.setTouched, form.errors],
  );

  return (
    <Form className={cn(className, styles.shippingForm)} onSubmit={form.handleSubmit} onReset={form.handleReset}>
      {title && <h4 className={styles.title}>{title}</h4>}
      <Row className={styles.row}>
        <Col>
          <Input
            isRequired
            type="text"
            label="First name"
            placeholder="First name"
            name={RECEIVER_FIRST_NAME}
            onChange={handleChange}
            onBlur={form.handleBlur}
            error={form.touched[RECEIVER_FIRST_NAME] ? form.errors[RECEIVER_FIRST_NAME] : undefined}
            value={form.values[RECEIVER_FIRST_NAME] || ''}
          />
        </Col>
        <Col>
          <Input
            isRequired
            label="Last Name"
            placeholder="Last Name"
            type="text"
            name={RECEIVER_LAST_NAME}
            onChange={handleChange}
            onBlur={form.handleBlur}
            error={form.touched[RECEIVER_LAST_NAME] ? form.errors[RECEIVER_LAST_NAME] : undefined}
            value={form.values[RECEIVER_LAST_NAME] || ''}
          />
        </Col>
      </Row>
      <Row className={styles.row}>
        <Col className={styles.col}>
          <Input
            isRequired
            label="Phone"
            type="text"
            placeholder="Phone"
            name={RECEIVER_PHONE}
            onChange={handleChange}
            onBlur={form.handleBlur}
            error={form.touched[RECEIVER_PHONE] ? form.errors[RECEIVER_PHONE] : undefined}
            value={form.values[RECEIVER_PHONE] || ''}
          />
        </Col>
        <Col className={styles.col}>
          <Input
            isRequired
            label="Email"
            placeholder="Email"
            type="text"
            name={RECEIVER_EMAIL}
            onChange={handleChange}
            onBlur={form.handleBlur}
            error={form.touched[RECEIVER_EMAIL] ? form.errors[RECEIVER_EMAIL] : undefined}
            value={form.values[RECEIVER_EMAIL] || ''}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default FormDigital;
