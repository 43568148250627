import cn from 'classnames';
import * as React from 'react';
import Lightbox from 'react-image-lightbox';

import styles from './LightboxBackgroundImage.module.scss';

interface IProps {
  className?: string;
  url: string;
  asImage?: boolean;
  alt?: string;
  preventDefault?: boolean;
  stopPropagation?: boolean;
  isScalable?: boolean;
}

// [WARNING!] Not working good in React.Strict mode!
// https://github.com/frontend-collective/react-image-lightbox/issues/589
const LightboxBackgroundImage = React.forwardRef(
  (
    {
      className,
      url,
      asImage = false,
      alt = '',
      preventDefault = false,
      stopPropagation = false,
      isScalable = true,
    }: IProps,
    ref: React.Ref<HTMLDivElement | HTMLImageElement>,
  ) => {
    const [isShown, setIsShown] = React.useState(false);

    const handleOpenLightbox = React.useCallback(
      (event: React.MouseEvent) => {
        if (preventDefault) {
          event.preventDefault();
        }
        if (stopPropagation) {
          event.stopPropagation();
        }

        setIsShown(true);
      },
      [preventDefault, stopPropagation],
    );

    const handleCloseLightbox = React.useCallback(
      (event?: any) => {
        if (preventDefault) {
          event.preventDefault();
        }
        if (stopPropagation) {
          event.stopPropagation();
        }

        setIsShown(false);
      },
      [preventDefault, stopPropagation],
    );

    const imageLayout = React.useMemo(() => {
      if (asImage) {
        return (
          <img
            ref={ref as React.RefObject<HTMLImageElement>}
            className={cn(styles.image, { [styles.notScalable]: !isScalable }, className)}
            role="button"
            onClick={isScalable ? handleOpenLightbox : void 0}
            src={url}
            alt={alt}
          />
        );
      }
      return (
        <div
          ref={ref as React.RefObject<HTMLDivElement>}
          className={cn(styles.container, className)}
          onClick={handleOpenLightbox}
          role="button"
          style={{ backgroundImage: `url("${url}")` }}
        />
      );
    }, [asImage, url, preventDefault, isScalable]);

    return (
      <React.Fragment>
        {imageLayout}
        {isShown && (
          <div onClick={(e) => stopPropagation && e.stopPropagation()}>
            <Lightbox
              mainSrc={url}
              enableZoom={false}
              onCloseRequest={handleCloseLightbox}
              onImageLoadError={handleCloseLightbox}
            />
          </div>
        )}
      </React.Fragment>
    );
  },
);

export default LightboxBackgroundImage;
