import { createSelector } from 'reselect';
import { ALLOWED_RECIPIENT_UID } from '../../constants/shipping';
import { IAppState } from '../../types/store';

export const selectRootState = (state: IAppState) => state.keys;

export const selectInitKeys = createSelector(selectRootState, ({ isInitialized, ...keys }) => keys);

export const selectIsInitialized = createSelector(selectRootState, (root) => root.isInitialized);

export const selectAllowedRecipientUid = createSelector(selectRootState, (root) => root[ALLOWED_RECIPIENT_UID]);

export const selectIsOneLinkFlow = createSelector(selectInitKeys, Boolean);
