import { IInitKeys } from '../../types/shipping';
import { ActionsEnum } from '../../types/store';

export const setKeysAction = (payload: IInitKeys) => {
  return { type: ActionsEnum.SetKeys, payload };
};

export const updateKeysAction = (payload: IInitKeys) => {
  return { type: ActionsEnum.UpdateKeys, payload };
};

export const clearKeysAction = () => {
  return { type: ActionsEnum.ClearKeys };
};
