import cn from 'classnames';
import * as React from 'react';
import styles from './BrandLogo.module.scss';

interface IProps {
  className?: string;
  alt?: string;
  image: string;
}

const BrandLogo = ({ alt = '', className, image }: IProps) => {
  return <img src={image} alt={alt} className={cn(styles.logo, className)} />;
};

export default BrandLogo;
