import cn from 'classnames';
import { Field, FieldProps, Form, Formik, FormikHelpers, FormikValues } from 'formik';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CustomizableItem from '../../components/CustomizableItem/CustomizableItem';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import { routes } from '../../constants/routes';
import { submitCustomizeItemsStep } from '../../store/actions/form';
import { selectCustomizableViewItems } from '../../store/selectors/derived';
import { customizedTextsValidation } from '../../util/validation';

import mainStyles from '../../views/App/App.module.scss';
import styles from './CustomizeItemContainer.module.scss';

interface ICustomizeItemForm {
  [internalId: string]: string | undefined;
}

const CustomizeItemContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const inventoryItems = useSelector(selectCustomizableViewItems);

  const handleStepSubmit = React.useCallback(
    (values: FormikValues, { setSubmitting }: FormikHelpers<ICustomizeItemForm>) => {
      setSubmitting(true);
      dispatch(
        submitCustomizeItemsStep(
          Object.entries(values).reduce((acc, [internalId, text]) => {
            acc[internalId] = text.trim();
            return acc;
          }, {} as { [k: string]: string }),
        ),
      );

      setSubmitting(false);
      history.push(routes.address);
    },
    [history, dispatch],
  );

  const initialValues: ICustomizeItemForm = React.useMemo(() => {
    return inventoryItems?.length
      ? inventoryItems.reduce((acc, item) => {
          const { internalId, item_customization_text } = item || {};
          if (internalId) {
            return {
              ...acc,
              [internalId]: item_customization_text || '',
            };
          }
          return { ...acc };
        }, {})
      : {};
  }, [inventoryItems]);

  const validationSchema = React.useMemo(
    () => (inventoryItems ? customizedTextsValidation(inventoryItems) : {}),
    [inventoryItems],
  );

  return (
    <Container className={mainStyles.AppWrapper}>
      <Formik initialValues={initialValues} onSubmit={handleStepSubmit} validationSchema={validationSchema}>
        {({ handleSubmit, isSubmitting }) => {
          return (
            <React.Fragment>
              <Row className={styles.contentContainer}>
                <Col xs={12}>
                  <Row>
                    <Form className={styles.itemsList}>
                      {inventoryItems?.map((item) => {
                        const {
                          name = '',
                          internalId,
                          image_url: imageUrl = '',
                          item_customization_required: isItemCustomizationRequired,
                          item_customization_text_label: itemCustomizationTextLabel,
                        } = item || {};

                        return (
                          <Field key={internalId} name={internalId}>
                            {({ field, meta }: FieldProps) => {
                              const { error, touched } = meta;

                              return (
                                <Col className={cn(styles.option)} key={internalId}>
                                  <CustomizableItem
                                    label={itemCustomizationTextLabel}
                                    value={field.value}
                                    itemName={name}
                                    name={field.name}
                                    imageUrl={imageUrl}
                                    isCustomizationRequired={isItemCustomizationRequired}
                                    error={touched ? error : undefined}
                                    onChange={field.onChange}
                                  />
                                </Col>
                              );
                            }}
                          </Field>
                        );
                      })}
                    </Form>
                  </Row>
                </Col>
              </Row>
              <Row className={styles.continueRow}>
                <SubmitButton onSubmit={handleSubmit} disabled={isSubmitting}>
                  Continue
                </SubmitButton>
              </Row>
            </React.Fragment>
          );
        }}
      </Formik>
    </Container>
  );
};

export default CustomizeItemContainer;
