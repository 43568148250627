import { ICampaignItem } from '../../types/pyg';
import {
  ActionsEnum,
  IAction,
  IFormState,
  ISubmitCustomItemsPayload,
  ISubmitMSKUPayload,
  ISubmitPYGPayload,
} from '../../types/store';
import { convertInventoryItemsToCustomizableItems, splitInventoryByQuantity } from '../../util/helpers';

const initialState: IFormState = {
  address: null,
  pickedCampaignId: null,
  pickedItems: null,
  customItems: null,
};

const reducer = (state: IFormState = initialState, action: IAction): IFormState => {
  switch (action.type) {
    case ActionsEnum.GetFormDataRequest: {
      return initialState;
    }
    case ActionsEnum.GetFormDataSuccess: {
      return {
        ...state,
      };
    }
    case ActionsEnum.SubmitPYG: {
      const { campaignId: pickedCampaignId } = action.payload as ISubmitPYGPayload;

      return {
        ...state,
        pickedCampaignId,
        pickedItems: null,
      };
    }
    case ActionsEnum.SubmitMSKU: {
      const { pickedItems } = action.payload as ISubmitMSKUPayload;
      return {
        ...state,
        pickedItems,
      };
    }
    case ActionsEnum.SetCustomizableItems: {
      const items = action.payload as ICampaignItem[] | null;
      return {
        ...state,
        customItems: items ? convertInventoryItemsToCustomizableItems(splitInventoryByQuantity(items)) : null,
      };
    }
    case ActionsEnum.SubmitCustomizedItems: {
      const updatedItems = { ...state.customItems };

      Object.entries((action.payload as ISubmitCustomItemsPayload) || {}).forEach(([internalId, text]) => {
        const itemToUpdate = updatedItems[internalId];
        if (itemToUpdate) {
          itemToUpdate.item_customization_text = text;
        }
      });

      return {
        ...state,
        customItems: updatedItems,
      };
    }
    default:
      return state;
  }
};

export default reducer;
