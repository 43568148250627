import cn from 'classnames';
import * as React from 'react';

import { ModeSwitcherStatesEnum } from '../../../types/ui';

import { ReactComponent as ExpandedModeIcon } from '../../../assets/icon-multiple-item.svg';
import { ReactComponent as SingleModeIcon } from '../../../assets/icon-single-item.svg';

import styles from './ModeSwitcher.module.scss';

export interface IModeSwitcherProps {
  mode: ModeSwitcherStatesEnum;
  onSwitch: (mode: ModeSwitcherStatesEnum) => void;
  className?: string;
}

const ModeSwitcher: React.FC<IModeSwitcherProps> = ({ mode = ModeSwitcherStatesEnum.Single, onSwitch, className }) => {
  const handleModeChange = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>, newMode: ModeSwitcherStatesEnum) => {
      e.preventDefault();
      if (mode !== newMode) {
        onSwitch(newMode);
      }
    },
    [onSwitch, mode],
  );
  return (
    <div className={cn(styles.container, className)}>
      <div
        className={cn(styles.button, styles.single, { [styles.active]: mode === ModeSwitcherStatesEnum.Single })}
        onClick={(e) => handleModeChange(e, ModeSwitcherStatesEnum.Single)}
      >
        <SingleModeIcon />
      </div>
      <div
        className={cn(styles.button, styles.expanded, { [styles.active]: mode === ModeSwitcherStatesEnum.Expanded })}
        onClick={(e) => handleModeChange(e, ModeSwitcherStatesEnum.Expanded)}
      >
        <ExpandedModeIcon />
      </div>
    </div>
  );
};

export default ModeSwitcher;
