import cn from 'classnames';
import * as React from 'react';
import { Card } from 'react-bootstrap';

import useWindowSize from '../../hooks/useWindowSize';
import { IPickedMskuItem } from '../../types/api';
import { ICampaignItem } from '../../types/pyg';
import AccordionItem from '../AccordionItem/AccordionItem';
import LightboxBackgroundImage from '../LightboxBackgroundImage/LightboxBackgroundImage';
import SkuOption from '../SkuOption/SkuOption';
import ToggleButton from '../ToggleButton/ToggleButton';

import styles from './ItemPicker.module.scss';

interface IProps {
  item: ICampaignItem;
  selected: IPickedMskuItem | null;
  onExpand: React.Dispatch<React.SetStateAction<string>>;
  onSelect: (item: IPickedMskuItem) => void;
  isExpandable?: boolean;
  className?: string;
  expanded?: boolean;
  children?: React.ReactNode;
  disabledItems?: string[];
}

const ItemPicker: React.FC<IProps> = ({
  className,
  item,
  expanded,
  onExpand,
  onSelect,
  isExpandable,
  selected,
  disabledItems,
}) => {
  const { name, image_url, sku_options, id, quantity = 1, internalId = '' } = item;

  const { mobile } = useWindowSize();

  const optionsCount = React.useMemo(() => sku_options?.length, [sku_options]);

  const pickedItemOptions = React.useMemo(() => {
    if (selected) {
      const { flavor, gender, color, size, other_option } =
        sku_options?.find(({ id: optionId }) => optionId === selected.uid) || {};
      const optionsArray = [flavor, gender, color, size, other_option];
      return optionsArray.filter(Boolean).join(', ');
    }
  }, [selected, sku_options]);

  const optionsInfo = React.useMemo(() => {
    return (
      <div className={styles.optionsContainer}>
        {optionsCount ? (
          <div className={styles.optionsCount}>
            {optionsCount} option{optionsCount === 1 ? '' : 's'}
          </div>
        ) : null}
        <div className={styles.detailsContainer}>
          {optionsCount && selected
            ? pickedItemOptions && (
                <React.Fragment>
                  <span className={styles.selectedText}>Selected:</span>
                  <span className={styles.selectedItems}>{pickedItemOptions}</span>
                </React.Fragment>
              )
            : optionsCount && <span className={styles.selectedText}>Awaiting selection</span>}
        </div>
      </div>
    );
  }, [selected, optionsCount, pickedItemOptions]);

  const toggleButton = React.useMemo(() => {
    return <ToggleButton className={styles.arrowButton} eventKey={internalId} expanded={expanded} />;
  }, [expanded, internalId, onExpand]);

  const isMskuItem = React.useMemo(() => {
    return sku_options && sku_options.length > 0;
  }, [sku_options]);

  const headingMarkup = React.useMemo(() => {
    return (
      <React.Fragment>
        <div
          className={cn(className, styles.headerContainer, {
            [styles.picked]: !!selected,
            [styles.simpleItem]: !isMskuItem,
          })}
        >
          {optionsCount && <div className={styles.checkmark} />}
          <LightboxBackgroundImage className={styles.itemImage} url={image_url} stopPropagation preventDefault />
          <p className={styles.name}>
            {name}
            <span className={styles.quantity}>{quantity > 1 && `x${quantity}`}</span>
          </p>
          {optionsCount && !mobile && optionsInfo}
          {optionsCount && toggleButton}
        </div>
        {mobile && optionsInfo}
      </React.Fragment>
    );
  }, [mobile, optionsInfo, quantity, image_url, selected, optionsCount, toggleButton, className, isMskuItem]);

  const handleSelectItem = React.useCallback(
    (uid: string) => {
      const count: number | undefined = sku_options?.find(({ id: optionId }) => optionId === uid)?.count;
      onSelect({ uid, parent_id: id, quantity, count });
    },
    [id, quantity, sku_options, onSelect],
  );

  const handleToggleItem = React.useCallback(() => {
    if (typeof onExpand === 'function' && isExpandable) {
      onExpand(expanded ? '' : internalId);
    }
  }, [internalId, onExpand, expanded, isExpandable]);

  return (
    <Card
      className={cn(styles.container, { [styles.picked]: !!selected, [styles.simpleItem]: !isMskuItem })}
      onClick={handleToggleItem}
    >
      <Card.Body style={{ padding: '10px' }}>
        <AccordionItem heading={headingMarkup} expanded={expanded}>
          <div className={styles.itemsContainer}>
            {sku_options?.map((skuOption) => {
              const isOutOfStock = (skuOption.count === 0 || skuOption.count === -1) && !skuOption.preorder_allowed;
              const isItemPicked = selected?.uid === skuOption.id;
              const isDisabled = (!isItemPicked && disabledItems?.includes(skuOption.id)) || isOutOfStock;
              return (
                <SkuOption
                  item={skuOption}
                  key={skuOption.id}
                  onSelect={handleSelectItem}
                  isPicked={isItemPicked}
                  isDisabled={isDisabled}
                />
              );
            })}
          </div>
        </AccordionItem>
      </Card.Body>
    </Card>
  );
};

export default ItemPicker;
