import { LOCATION_CHANGE, push, replace, RouterState } from 'connected-react-router';
import { call, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { Request } from '../../api/request';
import { apiUrl, endpoints } from '../../constants/api';
import { ErrorCodeEnum } from '../../constants/errors';
import { IRouterActions, routes } from '../../constants/routes';
import * as Analytics from '../../hooks/useAnalytics';
import { IResponse } from '../../types/http';
import { ICampaign, ICampaignItem } from '../../types/pyg';
import { IInitKeys } from '../../types/shipping';
import {
  ActionsEnum,
  IAction,
  IAuthorizeReceiverPayload,
  IAuthorizeReceiverSuccessPayload,
  IError,
  IInitSuccessPayload,
  ISetKeysPayload,
  ISetWhiteLabelingPayload,
} from '../../types/store';
import { isMskuItem, parseInitQuery, splitInventoryByQuantity, validateInitQuery } from '../../util/helpers';
import { setCustomizableItems } from '../actions/form';
import * as InitActions from '../actions/init';
import * as KeysActions from '../actions/keys';
import { selectCampaigns, selectIsFormSubmitted, selectIsLoading, selectItems, selectUserId } from '../selectors/init';
import { selectInitKeys } from '../selectors/keys';
import { prepareAuthHeadersSaga } from './helpers';

function* initSaga(action?: IAction<string>): Generator<any, any, any> {
  const initString = action?.payload;

  // 1. try to get the keys from query string otherwise grab them from redux
  const keys: IInitKeys = initString ? parseInitQuery(initString) : yield select(selectInitKeys);

  if (!validateInitQuery(keys)) {
    yield call(terminateSaga);
    return;
  }

  try {
    const isLoading: boolean = yield select(selectIsLoading);
    if (isLoading) {
      return;
    }

    // 2. make the init request
    if (Object.keys(keys).length) {
      yield put(KeysActions.setKeysAction(keys));
      yield put(InitActions.getFormDataRequestAction(keys));

      // 3. handle success and failure cases
      const [success, failure] = yield race([
        take(ActionsEnum.GetFormDataSuccess),
        take(ActionsEnum.GetFormDataFailure),
      ]);

      if (success) {
        // 4. if success - redirect user to the proper step
        yield call(initRedirectSaga);
        const userId = yield select(selectUserId);
        Analytics.config({ userId });
      }

      if (failure) {
        yield call(Analytics.apiException, failure.payload);

        // 5. if failure - check if the error is related to the password
        const { status, error_code, ds_customized_texts, org_info, sender_info } = failure.payload;

        if (
          status === 401 &&
          (error_code === ErrorCodeEnum.PasswordRequired || error_code === ErrorCodeEnum.RequiredTwoFA)
        ) {
          // 5.1 if password or 2fa is required - redirect user to the auth
          yield put(replace(routes.authentication));
          yield put(KeysActions.updateKeysAction({ allowed_recipient_uid: '' }));
          yield put(
            InitActions.setWhiteLabeling({ ds_customized_texts, org_info, sender_info } as ISetWhiteLabelingPayload),
          );
        } else {
          // 5.2 if failure - deny the app start
          yield call(terminateSaga, failure.payload);
        }
      }
    } else {
      // if there are no keys - deny the app start
      yield call(terminateSaga);
    }
  } catch (e) {
    yield call(terminateSaga, e);
    yield call(Analytics.exception, e);
  }
}

export function* getFormDataRequestWorkerSaga(action: IAction<ISetKeysPayload>) {
  const { resolve, reject } = action.payload || {};

  try {
    let queryParams;
    let endpoint;
    const headers: Record<string, string> = yield call(prepareAuthHeadersSaga);

    const { one_link_id, key, order_id } = action.payload || {};

    if (order_id && key) {
      queryParams = { order_id, key };
      endpoint = `${apiUrl}${endpoints.init}`;
    } else if (one_link_id) {
      queryParams = { one_link_id };
      endpoint = `${apiUrl}${endpoints.oneLinkInit}`;
    } else {
      yield put(InitActions.getFormDataFailureAction({ message: 'An unexpected error has occurred.' }));
    }

    if (endpoint && queryParams) {
      const initResponse: IResponse<IInitSuccessPayload | IError> = yield call<any>(Request, {
        headers,
        endpoint,
        queryParams,
      });

      if (initResponse.ok) {
        const { body } = initResponse as IResponse<IInitSuccessPayload>;
        if (resolve) {
          yield call(resolve, body);
        }
        yield put(InitActions.getFormDataSuccessAction(body));
        yield call(initRedirectSaga);
      } else {
        const { body, status } = initResponse;
        if (reject) {
          yield call(reject, body);
        }
        yield put(InitActions.getFormDataFailureAction({ ...body, status }));
      }
    } else {
      console.warn('No data was provided for the request');
    }
  } catch (e) {
    if (reject) {
      yield call(reject, e);
    }
    yield put(InitActions.getFormDataFailureAction(e));
  }
}

export function* authorizeReceiverWorkerSaga(action: IAction<IAuthorizeReceiverPayload>) {
  const { resolve, reject } = action.payload || {};
  const keys: IInitKeys = yield select(selectInitKeys);

  try {
    const endpoint = `${apiUrl}${endpoints.authorizeReceiver}`;

    const response: IResponse<IAuthorizeReceiverSuccessPayload | IError> = yield call<any>(Request, {
      endpoint,
      method: 'POST',
      body: JSON.stringify(action.payload),
    });

    if (response.ok) {
      const { body } = response as IResponse<IAuthorizeReceiverSuccessPayload>;

      if (resolve) {
        yield call(resolve, body);
      }

      const { id } = body;
      const { action: authAction } = action.payload || {};

      if (id && authAction === 'init') {
        yield put(KeysActions.updateKeysAction({ allowed_recipient_uid: id }));
      }

      if (authAction === 'verify') {
        yield put(InitActions.getFormDataRequestAction(keys));
      }
    } else {
      const { body } = response;
      if (reject) {
        yield call(reject, body);
      }
    }
  } catch (e) {
    if (reject) {
      yield call(reject, e);
    }
  }
}

export function* terminateSaga(error?: any) {
  // pass an init error via the router state
  yield put(push(routes.error, error));
}

export function* initRedirectSaga() {
  const campaigns: ICampaign[] | null = yield select(selectCampaigns);
  const items: ICampaignItem[] | null = yield select(selectItems);

  const physicalItems = items?.filter((item) => !item?.sku_options?.length);

  const isPhysicalSendWithCustomizableItems =
    items &&
    items.length &&
    items.every((item) => !item?.sku_options?.length) &&
    items.some((item) => item?.customization?.item_customization_allowed);

  let redirectTo = routes.address;

  if (campaigns && campaigns.length) {
    redirectTo = routes.pyg;
  }

  if (items && items.length && items.some(isMskuItem)) {
    redirectTo = routes.pickMsku;
  }

  if (isPhysicalSendWithCustomizableItems) {
    const customizableItems = physicalItems?.filter((item) => item?.customization?.item_customization_allowed);
    yield put(setCustomizableItems(splitInventoryByQuantity(customizableItems || [])));
    redirectTo = routes.customizeItems;
  }

  yield put(replace(redirectTo));
}

function* locationWorkerSaga(action: IAction<RouterState>): Generator<any, any, any> {
  const { action: routerAction } = action.payload || {};

  const isFormSubmitted = yield select(selectIsFormSubmitted);

  if (isFormSubmitted && routerAction === IRouterActions.BACK) {
    yield call(initSaga);
  }

  yield call(Analytics.page);
}

const sagas = {
  *watchInitApp() {
    yield takeLatest(ActionsEnum.InitApp, initSaga);
  },
  *watchInitRequest() {
    yield takeEvery(ActionsEnum.GetFormDataRequest, getFormDataRequestWorkerSaga);
  },
  *watchAuthorizeReceiverRequest() {
    yield takeEvery(ActionsEnum.AuthorizeReceiverRequest, authorizeReceiverWorkerSaga);
  },
  *watchLocationChange() {
    yield takeEvery(LOCATION_CHANGE, locationWorkerSaga);
  },
};

export default sagas;
