import { push, replace } from 'connected-react-router';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Request } from '../../api/request';
import { apiUrl, endpoints } from '../../constants/api';
import { ErrorCodeEnum } from '../../constants/errors';
import { routes } from '../../constants/routes';
import { SUCCESS_MESSAGE, SUCCESS_TITLE } from '../../constants/ui';
import * as Analytics from '../../hooks/useAnalytics';
import { ICustomizableItem, IPickedMskuItem } from '../../types/api';
import { IResponse } from '../../types/http';
import { ICampaign, ICampaignItem } from '../../types/pyg';
import {
  ActionsEnum,
  IAction,
  IError,
  ISubmitAddressPayload,
  ISubmitMSKUPayload,
  ISubmitPYGPayload,
  TUpdateRequestBody,
} from '../../types/store';
import {
  convertPickedItemsToInventoryItems,
  getCustomizableItemsList,
  isCustomizableItem,
  isCustomizableItemsIncluded,
  isMskuIncluded,
} from '../../util/helpers';
import { setCustomizableItems, updateFailureAction, updateSuccessAction } from '../actions/form';
import { setIsFormSubmitted } from '../actions/init';
import * as KeysActions from '../actions/keys';
import {
  selectCustomizableItemsForUpdateRequest,
  selectPickedCampaignId,
  selectPickedItemsForUpdateRequest,
} from '../selectors/form';
import { selectCampaigns, selectItems } from '../selectors/init';
import { selectInitKeys } from '../selectors/keys';
import { prepareAuthHeadersSaga } from './helpers';

function* updateSaga(action: IAction<ISubmitAddressPayload>) {
  const { values: shippingValues, resolve, reject } = action.payload || {};

  try {
    const { order_id, key, one_link_id } = yield select(selectInitKeys);
    // tslint:disable-next-line:variable-name
    const pre_created_engagement_id = (yield select(selectPickedCampaignId)) as string;
    const pickedItems: IPickedMskuItem[] | null = yield select(selectPickedItemsForUpdateRequest);
    const customizedItems: ICustomizableItem[] | null = yield select(selectCustomizableItemsForUpdateRequest);

    let endpoint = '';
    const headers: Record<string, string> = yield call(prepareAuthHeadersSaga);

    const requestBody: TUpdateRequestBody = {
      ...shippingValues,
      pre_created_engagement_id,
      ...pickedItems,
      ...customizedItems,
    };

    if (order_id && key) {
      endpoint = `${apiUrl}${endpoints.updateAddress}`;
      requestBody.order_id = order_id;
      requestBody.key = key;
    } else if (one_link_id) {
      endpoint = `${apiUrl}${endpoints.createOneLinkEngagement}`;
      requestBody.one_link_id = one_link_id;
    } else if (reject) {
      yield call(reject);
      yield call(Analytics.exception, { message: 'Unexpected error - no keys data in update request' });
      return;
    }

    const response: IResponse<null | { error: IError }> = yield call<any>(Request, {
      headers,
      endpoint,
      method: 'POST',
      body: JSON.stringify(requestBody),
    });

    if (response.ok) {
      if (resolve) {
        yield call(resolve, response.body);
      }

      yield put(
        push(routes.success, {
          message: SUCCESS_MESSAGE,
          title: SUCCESS_TITLE,
          status: response.status,
        }),
      );
      yield put(updateSuccessAction());
      yield put(setIsFormSubmitted(true));
    } else {
      const { title, message, error_code } = (response.body as IError) || {};
      if (response.status === 409 || (response.status === 400 && error_code === ErrorCodeEnum.OutOfStock)) {
        yield put(
          push(routes.error, {
            message,
            title,
            status: response.status,
          }),
        );
      } else if (response.status === 401) {
        yield put(replace(routes.authentication));
        yield put(KeysActions.updateKeysAction({ allowed_recipient_uid: '' }));
      } else {
        if (reject) {
          yield call(reject, response.body);
        }
      }
      yield put(updateFailureAction(response.body as IError));
      yield call(Analytics.apiException, response.body);
    }
  } catch (e) {
    if (reject) {
      yield call(reject, e);
    }
    yield put(updateFailureAction(e));
    yield call(Analytics.exception, e);
  }
}

function* submitFlowStepWorkerSaga(action: IAction<ISubmitPYGPayload | ISubmitMSKUPayload>): Generator<any, any, any> {
  let redirectTo: string = routes.address;
  let shouldSetMsku = false;
  let shouldSetCustomItems = false;

  const { resolve } = action.payload || {};

  yield put(setCustomizableItems(null));

  switch (action.type) {
    case ActionsEnum.SubmitPYG: {
      const { campaignId } = (action.payload as ISubmitPYGPayload) || {};
      if (campaignId) {
        const campaignList: ICampaign[] | null = yield select(selectCampaigns);
        const campaign = campaignList?.find(({ id }) => id === campaignId);
        shouldSetMsku = !!campaign && isMskuIncluded(campaign);
        shouldSetCustomItems = !!campaign && isCustomizableItemsIncluded(campaign.items);

        if (shouldSetMsku) {
          redirectTo = routes.pickMsku;
          break;
        }

        if (shouldSetCustomItems) {
          const customizableItems = campaign?.items.filter(isCustomizableItem);
          yield put(setCustomizableItems(customizableItems || []));
          redirectTo = routes.customizeItems;
        }
      }
      break;
    }
    case ActionsEnum.SubmitMSKU: {
      const { pickedItems } = (action.payload as ISubmitMSKUPayload) || {};
      const inventoryItems: ICampaignItem[] | null = yield select(selectItems);
      const customizableInventoryItems = yield call(getCustomizableItemsList, inventoryItems);
      const pickedItemsList = yield call(convertPickedItemsToInventoryItems, pickedItems, inventoryItems);
      const customizableItems = [...customizableInventoryItems, ...pickedItemsList.filter(isCustomizableItem)];
      const shouldCustomizeItems = customizableItems.length > 0;

      if (shouldCustomizeItems) {
        yield put(setCustomizableItems(customizableItems));
        redirectTo = routes.customizeItems;
      }
      break;
    }
    default:
      redirectTo = routes.address;
  }

  if (resolve) {
    yield call(resolve, redirectTo);
  }
}

const sagas = {
  *watchUpdateRequest() {
    yield takeLatest(ActionsEnum.UpdateRequest, updateSaga);
  },
  *watchSubmitFlowStep() {
    yield takeLatest([ActionsEnum.SubmitPYG, ActionsEnum.SubmitMSKU], submitFlowStepWorkerSaga);
  },
};

export default sagas;
