import { createTransform } from 'redux-persist';

import { IKeysState } from '../../types/store';

const keysStateTransform = createTransform(
  (stateIn: IKeysState) => stateIn,
  (stateOut: IKeysState) => {
    return {
      ...stateOut,
      error: null,
      isInitialized: false,
    };
  },
  { whitelist: ['keys'] },
);

const transformArray = [keysStateTransform];

export default transformArray;
