import * as React from 'react';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { routes } from '../../constants/routes';
import { CUSTOMIZE_STEP_BODY_TEXT } from '../../constants/ui';
import { selectGreetingTitle, selectPrioritizedWL, selectSenderWLData } from '../../store/selectors/derived';
import HtmlView from '../HtmlView/HtmlView';

import styles from './Greeting.module.scss';

interface IProps {
  className?: string;
}

const Greeting = ({ className }: IProps) => {
  const { pathname } = useLocation();

  const { sender_company_name, sender_first_name, sender_last_name } = useSelector(selectSenderWLData) || {};
  const title = useSelector(selectGreetingTitle(pathname));
  const { pick_your_gift_body, customize_items_page_body, msku_page_body, shipping_page_body } =
    useSelector(selectPrioritizedWL);

  const defaultPygBody = React.useMemo(() => {
    return (
      <p className={styles.body}>
        You are about to receive a gift from{' '}
        <span className={styles.sender}>
          {sender_first_name} from {sender_company_name}
        </span>
        <br />
        Select one of the following packs you want to receive.
      </p>
    );
  }, [sender_first_name, sender_company_name]);

  const defaultShippingPageBody = React.useMemo(() => {
    return (
      <>
        <p>To make sure you get this gift, please confirm your preferred ship-to address and it will be on its way!</p>

        <div className={styles.sender}>
          {`${sender_first_name} ${sender_last_name}`} with {sender_company_name}
        </div>
      </>
    );
  }, [sender_first_name, sender_last_name, sender_company_name]);

  const displayedBody = React.useMemo(() => {
    switch (pathname) {
      case routes.pyg:
        return pick_your_gift_body ? (
          <HtmlView html={pick_your_gift_body} className={styles.customBodyContainer} />
        ) : (
          defaultPygBody
        );
      case routes.pickMsku:
        return msku_page_body ? <HtmlView html={msku_page_body} className={styles.customBodyContainer} /> : null;
      case routes.customizeItems:
        return customize_items_page_body ? (
          <HtmlView html={customize_items_page_body} className={styles.customBodyContainer} />
        ) : (
          CUSTOMIZE_STEP_BODY_TEXT
        );
      case routes.address:
        return shipping_page_body ? (
          <HtmlView html={shipping_page_body} className={styles.customBodyContainer} />
        ) : (
          defaultShippingPageBody
        );
      default:
        return null;
    }
  }, [
    pathname,
    pick_your_gift_body,
    shipping_page_body,
    msku_page_body,
    customize_items_page_body,
    defaultPygBody,
    defaultShippingPageBody,
  ]);

  return (
    <Col xs={12} className={className}>
      <h1 className={styles.title}>{title}</h1>
      {displayedBody}
    </Col>
  );
};

export default Greeting;
