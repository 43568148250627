import cn from 'classnames';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import NotFoundErrorImage from '../../assets/image-404.svg';
import CommonErrorImage from '../../assets/image-error.svg';
import SuccessImage from '../../assets/image-success.svg';

import { DEFAULT_ERROR_MESSAGE, DEFAULT_ERROR_TITLE } from '../../constants/errors';
import { FOOTER_DISCLAIMER } from '../../constants/ui';
import { selectOrgLogo } from '../../store/selectors/derived';
import { selectResultScreenColor } from '../../store/selectors/init';
import { isLightColor } from '../../util/ui';
import BrandLogo from '../BrandLogo/BrandLogo';

import styles from './ResultComponent.module.scss';

const ResultComponent = () => {
  const { state } = useLocation<{ message: string; title?: string; status?: number }>();

  const backgroundColor = useSelector(selectResultScreenColor);
  const orgLogo = useSelector(selectOrgLogo);

  const isBackgroundLight = React.useMemo(() => {
    return isLightColor(backgroundColor);
  }, [backgroundColor]);

  const message = React.useMemo(() => (state && state.message ? state.message : DEFAULT_ERROR_MESSAGE), [state]);
  const heading = React.useMemo(() => (state && state.title ? state.title : DEFAULT_ERROR_TITLE), [state]);
  const status = React.useMemo(() => (state && state.status ? state.status : 400), [state]);

  const { focusImage, footerText } = React.useMemo(() => {
    const successText = (
      <React.Fragment>
        Visit{' '}
        <a href="https://imprintengine.com" className={styles.footerLink}>
          Imprintengine.com
        </a>{' '}
        for more opportunities!
      </React.Fragment>
    );

    switch (status) {
      case 200:
      case 201:
      case 204:
        return {
          focusImage: SuccessImage,
          footerText: successText,
        };
      case 404:
        return { focusImage: NotFoundErrorImage, footerText: FOOTER_DISCLAIMER };
      default:
        return { focusImage: CommonErrorImage, footerText: FOOTER_DISCLAIMER };
    }
  }, [status]);

  return (
    <div className={styles.container} style={{ backgroundColor }}>
      <Container as="header" className={styles.header}>
        <BrandLogo image={orgLogo} />
      </Container>
      <Container as="main" className={cn(styles.main, { [styles.lightBackground]: isBackgroundLight })}>
        <Row className={styles.content}>
          <Col xs={12} md={6} className={styles.text}>
            <h1 className={styles.title}>{heading}</h1>
            <div className={styles.message} dangerouslySetInnerHTML={{ __html: message }} />
          </Col>
          <Col xs={12} md={6} className={styles.focus}>
            <img className={styles.focusImage} src={focusImage} alt="" />
          </Col>
        </Row>
      </Container>
      <Container as="footer" className={cn(styles.footer, { [styles.lightBackground]: isBackgroundLight })}>
        <span className={styles.footerMessage}>{footerText}</span>
        <span className={styles.powered}>
          Powered by{' '}
          <a href="https://imprintengine.com" className={styles.footerLink}>
            Imprint Engine
          </a>
          . All rights reserved
        </span>
      </Container>
    </div>
  );
};

export default ResultComponent;
