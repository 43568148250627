import { useDebounceCallback } from '@react-hook/debounce';
import cn from 'classnames';
import * as React from 'react';
import { Form } from 'react-bootstrap';

import styles from './AutoCompleteInput.module.scss';

import { Suggestion } from '../../models/address';
import { IAddressSuggestion } from '../../types/shipping';
import { SmartyStreetsProvider } from '../../util/SmartyStreetsProvider';

import useClickOutside from '../../hooks/useClickOutside';

import editIcon from '../../assets/edit.svg';

interface IProps {
  onSelect: (i: any) => void;
  onReset?: () => void;
  label?: string;
  name: string;
  value?: string;
  onChange: (i: any) => void;
  onBlur: (i: any) => void;
  autoComplete?: string;
  error?: string | string[];
  isRequired?: boolean;
}

const AutoCompleteInput = ({
  name,
  value: query = '',
  onChange,
  onBlur,
  onSelect,
  onReset,
  autoComplete,
  label,
  isRequired,
  error,
}: IProps) => {
  const providerRef = React.useRef(new SmartyStreetsProvider());
  const [suggestions, setSuggestions] = React.useState<IAddressSuggestion[]>([]);
  const [isReadonly, setReadonly] = React.useState(false);

  const dropdownElement = React.useRef(null);

  useClickOutside(dropdownElement, () => setSuggestions([]));

  const queryAddress = useDebounceCallback(
    async (searchQuery) => {
      if (providerRef && providerRef.current) {
        if (searchQuery && searchQuery.length > 2) {
          const newSuggestions = await providerRef.current.query(searchQuery);
          if (newSuggestions && Array.isArray(newSuggestions)) {
            setSuggestions(newSuggestions.map((s: IAddressSuggestion) => new Suggestion(s)));
          }
        }
      }
    },
    700,
    false,
  );

  const handleSelect = React.useCallback(
    (suggestion: IAddressSuggestion) => {
      setSuggestions([]);
      if (onReset) {
        setReadonly(true);
      }
      onSelect(suggestion);
    },
    [onReset, setSuggestions, setReadonly, onSelect],
  );

  const handleReset = () => {
    if (onReset) {
      setReadonly(false);
      onReset();
    }
  };

  const renderValidationError = React.useMemo(() => {
    if (!error) {
      return null;
    }
    return Array.isArray(error) ? (
      error.map((e) => (
        <span key={e} className={styles.error}>
          {e}
        </span>
      ))
    ) : (
      <span className={styles.error}>{error}</span>
    );
  }, [error]);

  return (
    <div className={cn(styles.autoCompleteInput)}>
      {label && (
        <Form.Label>
          {label} {isRequired ? <span className={styles.asterisk}>*</span> : ''}
        </Form.Label>
      )}
      <div className={styles.inputContainer}>
        <Form.Control
          name={name}
          type="text"
          placeholder="Start typing the address"
          onChange={onChange}
          onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => queryAddress(e.currentTarget.value)}
          className={cn({ [styles.errorBorder]: !!error })}
          value={query}
          readOnly={isReadonly}
          autoComplete={autoComplete}
          onBlur={onBlur}
        />
        {renderValidationError}
        {onReset && isReadonly && (
          <button
            title="Reset address"
            className={styles.clearButton}
            onClick={handleReset}
            style={{ backgroundImage: `url(${editIcon})` }}
          />
        )}
        {suggestions && suggestions.length !== 0 && (
          <div className={styles.suggestionsList} ref={dropdownElement}>
            {suggestions.map((s: IAddressSuggestion) => (
              <button
                className={styles.suggestionsListItem}
                key={s.toString()}
                onClick={() => handleSelect(s)}
                type="button"
              >
                {s.toString()}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

AutoCompleteInput.propTypes = {};

export default AutoCompleteInput;
