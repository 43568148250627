import { ICampaignItem } from '../../types/pyg';
import {
  ActionsEnum,
  ISubmitAddressPayload,
  ISubmitCustomItemsPayload,
  ISubmitMSKUPayload,
  ISubmitPYGPayload,
} from '../../types/store';

export const updateRequestAction = (payload: ISubmitAddressPayload) => ({ type: ActionsEnum.UpdateRequest, payload });
export const updateFailureAction = (payload: unknown) => ({ type: ActionsEnum.UpdateFailure, payload });
export const updateSuccessAction = () => ({ type: ActionsEnum.UpdateSuccess });

export const submitPickYourGiftStep = (payload: ISubmitPYGPayload) => ({
  type: ActionsEnum.SubmitPYG,
  payload,
});

export const submitMSKUStep = (payload: ISubmitMSKUPayload) => ({
  type: ActionsEnum.SubmitMSKU,
  payload,
});

export const setCustomizableItems = (payload: ICampaignItem[] | null) => ({
  type: ActionsEnum.SetCustomizableItems,
  payload,
});

export const submitCustomizeItemsStep = (payload: ISubmitCustomItemsPayload) => ({
  type: ActionsEnum.SubmitCustomizedItems,
  payload,
});
