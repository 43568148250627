import debounce from 'lodash/debounce';
import * as React from 'react';

import { DEFAULT_SCROLL_DELAY_MS } from '../constants/ui';

const useScrollPosition = (delay?: number) => {
  const [scrollPosition, setScrollPosition] = React.useState(0);

  const handleScrollChange = React.useCallback(
    debounce(() => {
      if (window) {
        const position = window.scrollY;
        setScrollPosition(position);
      }
    }, delay || DEFAULT_SCROLL_DELAY_MS),
    [],
  );

  React.useEffect(() => {
    if (window) {
      window.addEventListener('scroll', handleScrollChange);

      return () => {
        window.removeEventListener('scroll', handleScrollChange);
      };
    }
  }, []);

  return {
    scrollY: scrollPosition,
  };
};

export default useScrollPosition;
