import classnames from 'classnames';
import * as React from 'react';
import { Card } from 'react-bootstrap';

import useWindowSize from '../../hooks/useWindowSize';
import { ICampaignItem } from '../../types/pyg';
import LightboxBackgroundImage from '../LightboxBackgroundImage/LightboxBackgroundImage';

import styles from './SkuOption.module.scss';

interface IProps {
  item: ICampaignItem;
  isPicked?: boolean;
  onSelect: (id: string) => void;
  children?: React.ReactNode;
  isDisabled?: boolean;
}

const SkuOption: React.FC<IProps> = ({ item, onSelect, isPicked, isDisabled }: IProps) => {
  const { name, color, flavor, gender, other_option, size, image_url, id } = item;
  const { mobile } = useWindowSize();

  const handleSkuOptionSelect = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onSelect(id);
    },
    [id, onSelect],
  );

  const optionsContainerMarkup = React.useMemo(() => {
    const optionsList = [flavor, size, color, gender, other_option];

    const filteredOptions = optionsList.filter((option) => option && option?.length > 0);

    const outOfStockTitle = (
      <span className={classnames(styles.outOfStockText, { [styles.addMargin]: filteredOptions.length })}>
        Out of stock
      </span>
    );

    return (
      <div className={styles.optionsContainer}>
        {filteredOptions.join(' | ')}
        {isDisabled && outOfStockTitle}
      </div>
    );
  }, [item, isDisabled]);

  return (
    <Card
      className={classnames(styles.card, { [styles.picked]: isPicked }, { [styles.disabled]: isDisabled })}
      onClick={handleSkuOptionSelect}
    >
      <div className={styles.itemContainer}>
        <LightboxBackgroundImage className={styles.itemImage} url={image_url} stopPropagation preventDefault />
        <div className={styles.infoContainer}>
          <div className={styles.headingContainer}>
            <span className={styles.itemName}>{name}</span>
            {mobile && optionsContainerMarkup}
          </div>
          {!mobile && optionsContainerMarkup}
        </div>
      </div>
    </Card>
  );
};

export default SkuOption;
