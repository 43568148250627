import './wdyr';

import { ConnectedRouter as Router } from 'connected-react-router';
import * as React from 'react';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import './index.scss';

import Routes from './Routes';
import { history, persistor, store } from './store';

const container = document.getElementById('root');
// Downgraded to React v17 because of random Safari issues
// that apparently are related to batch updates in v18
// Open issue: https://github.com/facebook/react/issues/26713
// const root = createRoot(container!);

ReactDom.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        {/*<React.StrictMode>*/}
        <Routes />
        {/*</React.StrictMode>*/}
      </Router>
    </PersistGate>
  </Provider>,
  container!,
);
