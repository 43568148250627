import { IAnalyticsEvent } from '../types/analytics';

export const SHIPPING_PAGE = 'Shipping Form';

export const PAGE_NAME = 'DelayedShipping_page_view';

export const trackFormSubmit = (eventValue: string): IAnalyticsEvent => ({
  eventAction: 'Confirm',
  eventLabel: `Confirm`,
  eventValue,
});

export const trackPageClose = (): IAnalyticsEvent => ({
  eventAction: 'Close',
  eventLabel: 'Closed without confirmation',
});
