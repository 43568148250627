import { all } from 'redux-saga/effects';

import form from './form';
import init from './init';

const allSagas: any = {
  ...init,
  ...form,
};

export default function* rootSaga() {
  yield all(Object.values(allSagas).map((saga: any) => saga()));
}
