import { select } from 'redux-saga/effects';
import { selectPassword } from '../selectors/init';
import { selectInitKeys } from '../selectors/keys';

export function* prepareAuthHeadersSaga(): Generator<any, any, any> {
  const { allowed_recipient_uid } = yield select(selectInitKeys);
  const authPassword: string = yield select(selectPassword);

  return {
    ...(authPassword ? { 'X-OL-API-KEY': authPassword } : {}),
    ...(allowed_recipient_uid ? { 'X-OL-User': allowed_recipient_uid } : {}),
  };
}
