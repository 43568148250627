import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import ResultComponent from './components/ResultComponent/ResultComponent';
import { RESULT_ROUTES, routes } from './constants/routes';
import Authentication from './containers/Authentication/Authentication';
import CustomizeItemContainer from './containers/CustomizeItemContainer/CustomizeItemContainer';
import PickMsku from './containers/PickMsku/PickMsku';
import PickYourGift from './containers/PickYourGift/PickYourGift';
import ShippingContainer from './containers/ShippingContainer/ShippingContainer';
import App from './views/App/App';

const Routes = () => (
  <App>
    <Switch>
      <Route path={routes.authentication} exact>
        <Authentication />
      </Route>
      <Route path={routes.address} exact>
        <ShippingContainer />
      </Route>
      <Route path={routes.pyg} exact>
        <PickYourGift />
      </Route>
      <Route path={routes.pickMsku} exact>
        <PickMsku />
      </Route>
      <Route path={routes.customizeItems} exact>
        <CustomizeItemContainer />
      </Route>
      <Route path={RESULT_ROUTES} exact>
        <ResultComponent />
      </Route>
    </Switch>
  </App>
);

export default Routes;
