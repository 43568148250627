import cn from 'classnames';
import * as React from 'react';
import Collapsible from 'react-collapsible';

import styles from './AccordionItem.module.scss';

export interface IAccordionItemProps {
  heading: string | React.ReactElement;
  children: React.ReactNode | React.ReactNode[];
  expanded?: boolean;
  className?: string;
}

const AccordionItem: React.FC<IAccordionItemProps> = ({ heading, children, expanded, className }) => {
  return (
    <Collapsible trigger={heading} className={cn(styles.container, className)} open={expanded}>
      {children}
    </Collapsible>
  );
};

export default AccordionItem;
