export enum IInventoryItemSizeEnum {
  Small = 'small',
  Big = 'big',
}

export interface ICustomizableViewItem {
  internalId: string;
  item_id: string;
  name?: string;
  image_url?: string;
  item_customization_text_label?: string;
  item_customization_text?: string;
  item_customization_allowed?: boolean;
  item_customization_required?: boolean;
}
