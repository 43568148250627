import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as IconKeyboardDown } from '../../assets/keyboard_arrow_down.svg';

import styles from './ToggleButton.module.scss';

interface IProps {
  eventKey: string;
  callback?: (key: string) => void;
  expanded?: boolean;
  className?: string;
}

const ToggleButton = ({ className, eventKey, callback, expanded }: IProps) => {
  const handleButtonClick = React.useCallback(() => {
    if (typeof callback === 'function') {
      return callback(eventKey);
    }
  }, [callback, eventKey]);

  return (
    <button className={cn(styles.arrowButton, className)} onClick={handleButtonClick}>
      <IconKeyboardDown className={cn(expanded ? styles.expanded : '')} />
    </button>
  );
};

export default ToggleButton;
