import { FormikHelpers, useFormik } from 'formik';
import * as React from 'react';
import { Col } from 'react-bootstrap';

import FormPhysical from '../../components/forms/FormPhysical';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import { trackFormSubmit } from '../../constants/analytics';
import { countryList } from '../../constants/countries';
import { FORMS_ENUM } from '../../constants/shipping';
import useAnalytics from '../../hooks/useAnalytics';
import { IPhysicalReceiverInfo } from '../../types/api';
import { IAddressSuggestion } from '../../types/shipping';
import { formatApiAddress } from '../../util/address';
import { physicalFormValidation as validationSchema } from '../../util/validation';

import styles from './ShippingForm.module.scss';

interface IProps {
  initialValues: IPhysicalReceiverInfo;
  isOneLink?: boolean;
  onSubmit: (
    values: Partial<IPhysicalReceiverInfo>,
    formikHelpers: FormikHelpers<Partial<IPhysicalReceiverInfo>>,
  ) => Promise<void>;
  children?: React.ReactNode;
}

const PhysicalShippingForm: React.FC<IProps> = ({ initialValues: initialAddress, onSubmit, isOneLink }) => {
  const { trackEvent } = useAnalytics();

  const initialValues = React.useMemo(() => {
    const country = countryList.find((c) => c.two_digit_code === initialAddress.receiver_country);
    return { ...initialAddress, receiver_country: country?.two_digit_code ?? '' } || ({} as IPhysicalReceiverInfo);
  }, [initialAddress]);

  const form = useFormik<Partial<IPhysicalReceiverInfo>>({
    initialValues,
    onSubmit: (values, actions) => {
      const castedValues = validationSchema.cast(values);
      if (castedValues) {
        onSubmit(castedValues, actions).then(() => trackEvent(trackFormSubmit(FORMS_ENUM.Physical)));
      }
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validationSchema,
  });

  const updateAddress = React.useCallback(() => {
    form.handleSubmit();
  }, [form]);

  const handleSelect = React.useCallback(
    (address: IAddressSuggestion) => {
      const addressData = formatApiAddress(address);

      if (addressData) {
        form.setValues({ ...form.values, ...addressData });
      }
    },
    [form],
  );

  return (
    <React.Fragment>
      <div className={styles.formWrapper}>
        <FormPhysical
          isOneLink={isOneLink}
          className={styles.form}
          form={form}
          onReset={form.resetForm}
          onSelect={handleSelect}
        />

        <Col className={styles.requiredMessage} xs={12}>
          <span className={styles.asterisk}>*</span> Required fields
        </Col>

        <Col xs={12} className={styles.actions}>
          <SubmitButton onSubmit={updateAddress} disabled={form.isSubmitting}>
            Confirm
          </SubmitButton>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default PhysicalShippingForm;
