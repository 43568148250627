import {
  RECEIVER_ADDRESS1,
  RECEIVER_ADDRESS2,
  RECEIVER_ADDRESS_JOINED,
  RECEIVER_CITY,
  RECEIVER_COMPANY_NAME,
  RECEIVER_COUNTRY,
  RECEIVER_EMAIL,
  RECEIVER_FIRST_NAME,
  RECEIVER_LAST_NAME,
  RECEIVER_PHONE,
  RECEIVER_STATE,
  RECEIVER_ZIP,
} from '../constants/shipping';
import { IDigitalReceiverInfo, IPhysicalReceiverInfo } from '../types/api';
import { IAddressSuggestion, IFormattedAddress } from '../types/shipping';

export const formatApiAddress = (address: IAddressSuggestion): IFormattedAddress | null => {
  const { city, secondary, state, streetLine, zipcode } = address;
  if (city && state && streetLine && zipcode) {
    return {
      [RECEIVER_CITY]: city,
      [RECEIVER_ADDRESS1]: streetLine,
      [RECEIVER_ADDRESS2]: secondary || '',
      [RECEIVER_STATE]: state,
      [RECEIVER_ZIP]: zipcode,
      [RECEIVER_ADDRESS_JOINED]: address.toString(),
    };
  }
  return null;
};

export const setEmptyAddress = () => ({
  [RECEIVER_CITY]: '',
  [RECEIVER_ADDRESS1]: '',
  [RECEIVER_ADDRESS2]: '',
  [RECEIVER_STATE]: '',
  [RECEIVER_ZIP]: '',
});

export const emptyPhysicalReceiver: IPhysicalReceiverInfo = {
  [RECEIVER_FIRST_NAME]: '',
  [RECEIVER_LAST_NAME]: '',
  [RECEIVER_ADDRESS1]: '',
  [RECEIVER_ADDRESS2]: '',
  [RECEIVER_STATE]: '',
  [RECEIVER_COUNTRY]: '',
  [RECEIVER_CITY]: '',
  [RECEIVER_EMAIL]: '',
  [RECEIVER_PHONE]: '',
  [RECEIVER_ZIP]: '',
  [RECEIVER_ADDRESS_JOINED]: '',
  [RECEIVER_COMPANY_NAME]: '',
};

export const emptyDigitalReceiver: IDigitalReceiverInfo = {
  [RECEIVER_FIRST_NAME]: '',
  [RECEIVER_LAST_NAME]: '',
  [RECEIVER_EMAIL]: '',
  [RECEIVER_PHONE]: '',
};
