import cn from 'classnames';
import * as React from 'react';

import { IInventoryItemSizeEnum } from '../../types/inventory';
import LightboxBackgroundImage from '../LightboxBackgroundImage/LightboxBackgroundImage';
import CustomizableItemIndicator from './CustomizableItemIndicator/CustomizableItemIndicator';

import styles from './InventoryItem.module.scss';

export interface IInventoryItemProps {
  id: string;
  name: string;
  image_url: string;
  hint?: string;
  quantity?: number | undefined;
  className?: string;
  size?: IInventoryItemSizeEnum;
  isScalable?: boolean;
  isCustomizationAllowed?: boolean;
}

const InventoryItem: React.FC<IInventoryItemProps> = ({
  id,
  name,
  image_url,
  quantity,
  className,
  size,
  hint,
  isScalable = true,
  isCustomizationAllowed,
}) => {
  return (
    <div key={id} className={cn(styles.item, { [styles.bigSize]: size === IInventoryItemSizeEnum.Big }, className)}>
      <div className={styles.metadataContainer}>
        <div className={styles.quantity}>x{quantity}</div>
        {isCustomizationAllowed && <CustomizableItemIndicator className={styles.customizableItemIcon} />}
      </div>
      <div className={cn(styles.image, { [styles.notScalable]: !isScalable })} title={hint}>
        <LightboxBackgroundImage asImage preventDefault url={image_url} alt={name} isScalable={isScalable} />
      </div>
      <div className={styles.name}>{name}</div>
    </div>
  );
};

export default InventoryItem;
