import cn from 'classnames';
import * as React from 'react';
import { Form } from 'react-bootstrap';

import styles from './Input.module.scss';

interface IProps {
  value: string;
  className?: string;
  label?: string;
  type?: 'number' | 'email' | 'password' | 'text';
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  isRequired?: boolean;
  error?: string | string[];
  disabled?: boolean;
  title?: string;
  placeholder?: string;
  maxLength?: number;
  onFocus?: (e: any) => void;
  autoFocus?: boolean;
  autoComplete?: boolean;
}

const Input = ({
  className,
  onChange,
  onBlur,
  isRequired,
  value,
  type,
  name,
  label,
  error,
  disabled,
  title,
  placeholder,
  maxLength,
  autoFocus,
  onFocus,
  autoComplete = true,
}: IProps) => {
  const renderValidationError = React.useMemo(() => {
    if (!error) {
      return null;
    }
    return Array.isArray(error) ? (
      error.map((e) => (
        <span key={e} className={styles.error}>
          {e}
        </span>
      ))
    ) : (
      <span className={styles.error}>{error}</span>
    );
  }, [error, label]);

  return (
    <div className={cn(className, styles.container)}>
      {label && (
        <Form.Label>
          {label} {isRequired ? <span className={styles.asterisk}>*</span> : ''}
        </Form.Label>
      )}
      <Form.Control
        disabled={disabled}
        type={type}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        className={cn({ [styles.errorBorder]: !!error, [styles.disabled]: disabled })}
        value={value}
        title={title}
        placeholder={placeholder}
        maxLength={maxLength}
        autoFocus={autoFocus}
        autoComplete={autoComplete ? 'on' : 'off'}
      />
      {renderValidationError}
    </div>
  );
};

export default Input;
