import cn from 'classnames';
import * as React from 'react';
import { Form } from 'react-bootstrap';

import { ReactComponent as ArrowDownIcon } from '../../assets/icon-arrow-down.svg';
import useClickOutside from '../../hooks/useClickOutside';
import Input from '../Input/Input';
import Loader from '../Loader/Loader';

import styles from './LookupSearch.module.scss';

interface IProps {
  name?: string;
  value?: string;
  className?: string;
  containerClassName?: string;
  onFocus?: (...args: any[]) => void;
  onBlur?: (...args: any[]) => void;
  onClick?: (...args: any[]) => void;
  onChange: (...args: any[]) => void;
  onDelete?: () => void;
  placeholder?: string;
  disabled?: boolean;
  isLoading?: boolean;
  result?: (data: any) => React.ReactNode | React.ReactNode[];
  label?: string;
  icon?: React.ReactNode;
  readOnly?: boolean;
  required?: boolean;
  autoComplete?: boolean;
  isClearable?: boolean;
  error?: string;
}

const LookupSearch: React.FC<IProps> = ({
  name,
  value,
  className,
  containerClassName,
  onFocus,
  onBlur,
  onClick,
  onChange,
  onDelete,
  placeholder,
  disabled,
  isLoading,
  result,
  label,
  readOnly,
  icon,
  required = false,
  autoComplete,
  error,
}: IProps) => {
  const [active, setActive] = React.useState(false);
  const lookupElement = React.useRef(null);

  const handleOnChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value: newValue } = e.currentTarget;

      if (!newValue && typeof onDelete === 'function') {
        onDelete();
      }

      if (typeof onChange === 'function') {
        onChange(e, newValue);
      }
    },
    [onChange, onDelete],
  );

  const handleOnFocus = React.useCallback(
    (...args: any[]) => {
      if (result) {
        setActive(true);
      }
      if (typeof onFocus === 'function') {
        onFocus(...args);
      }
    },
    [result, onFocus],
  );

  const handleOnBlur = React.useCallback(
    (...args: any[]) => {
      setActive(false);

      if (typeof onBlur === 'function') {
        onBlur(...args);
      }
    },
    [onBlur],
  );

  /* onBlur workround
   * https://github.com/facebook/react/issues/9142
   */
  useClickOutside(lookupElement, handleOnBlur);

  React.useEffect(() => {
    if (isLoading) {
      setActive(isLoading);
    }
  }, [isLoading]);

  return (
    <div
      className={cn(styles.lookupSearchContainer, containerClassName, {
        [styles.loading]: isLoading,
        [styles.readOnly]: readOnly,
      })}
      ref={lookupElement}
    >
      {label && (
        <Form.Label>
          {label} {required ? <span className={styles.asterisk}>*</span> : ''}
        </Form.Label>
      )}
      <div className={cn(styles.inputWrapper)} onClick={onClick}>
        {!readOnly && (
          <div className={cn(styles.icon)}>
            {icon ? (
              icon
            ) : (
              <ArrowDownIcon className={cn({ [styles.opened]: active })} onClick={() => setActive((prev) => !prev)} />
            )}
          </div>
        )}
        <Input
          name={name}
          type="text"
          className={cn(styles.input, className)}
          onFocus={(e) => handleOnFocus(e, value)}
          onChange={handleOnChange}
          placeholder={placeholder}
          disabled={disabled}
          value={value || ''}
          error={error}
          autoComplete={autoComplete}
        />
      </div>
      <div className={cn(styles.resultWrapper, { [styles.active]: active })}>
        <div className={cn(styles.spinner)}>
          <Loader isLoading={!!isLoading} />
        </div>
        {result && <div className={cn(styles.result)}>{result({ setActive })}</div>}
      </div>
    </div>
  );
};

export default LookupSearch;
