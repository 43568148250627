import { createSelector } from 'reselect';

import { IAppState } from '../../types/store';
import { prepareCustomizedItemsForRequest, preparePickedItemsForAPI } from '../../util/helpers';

export const selectRootState = (state: IAppState) => state.form;

export const selectPickedCampaignId = createSelector(selectRootState, (state) => state.pickedCampaignId);

export const selectPickedItems = createSelector(selectRootState, (state) => state.pickedItems || {});

export const selectCustomizableItems = createSelector(selectRootState, (state) => state.customItems);

export const selectPickedItemsForUpdateRequest = createSelector(selectPickedItems, preparePickedItemsForAPI);

export const selectCustomizableItemsForUpdateRequest = createSelector(selectCustomizableItems, (customizableItems) => {
  return prepareCustomizedItemsForRequest(customizableItems);
});
