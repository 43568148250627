import cn from 'classnames';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import QuestionMarkImage from '../../assets/icon-question.svg';

import CampaignItem from '../../components/CampaignItem/CampaignItem';
import ItemPicker from '../../components/ItemPicker/ItemPicker';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import { MSKU_NOT_PICKED_ITEM_HINT } from '../../constants/ui';
import useWindowSize from '../../hooks/useWindowSize';
import { submitMSKUStep } from '../../store/actions/form';
import { selectPickedCampaign } from '../../store/selectors/derived';
import { selectPickedItems } from '../../store/selectors/form';
import { selectIsDigital, selectItems } from '../../store/selectors/init';
import { IPickedMskuItem } from '../../types/api';
import { ICampaignItem } from '../../types/pyg';
import { convertInventoryItemsToPickedItems } from '../../util/helpers';

import mainStyles from '../../views/App/App.module.scss';
import styles from './PickMsku.module.scss';

const PickMsku = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { tablet } = useWindowSize();

  const inventoryItems = useSelector(selectItems);
  const storedPickedItems = useSelector(selectPickedItems);
  const pickedCampaign = useSelector(selectPickedCampaign);
  const isDigital = useSelector(selectIsDigital);

  const [expandedItem, setExpandedItem] = React.useState('');
  const [pickedItems, setPickedItems] = React.useState<{ [id: string]: IPickedMskuItem }>(
    storedPickedItems && Object.keys(storedPickedItems).length
      ? { ...storedPickedItems }
      : convertInventoryItemsToPickedItems(inventoryItems || []),
  );

  const pickedValues = React.useMemo(() => Object.values(pickedItems), [pickedItems]);

  const handleSelectItem = React.useCallback((selectedItem: { [id: string]: IPickedMskuItem }) => {
    setPickedItems((existingItems) => ({ ...existingItems, ...selectedItem }));
  }, []);

  const handleContinue = React.useCallback(
    () =>
      new Promise<string>((resolve) => {
        dispatch(submitMSKUStep({ pickedItems, resolve }));
      }).then(history.push),
    [dispatch, history, pickedItems],
  );
  const isStepDisabled = React.useMemo(() => {
    const mskuItemsToPick = inventoryItems?.filter((item) => !!item.sku_options?.length);

    return !(pickedValues.length && pickedValues.every(Boolean) && mskuItemsToPick?.length === pickedValues.length);
  }, [pickedValues, inventoryItems]);

  React.useEffect(() => {
    if (!storedPickedItems && !Object.keys(pickedItems).length && inventoryItems?.length) {
      setPickedItems(convertInventoryItemsToPickedItems(inventoryItems));
    }
  }, [storedPickedItems, pickedItems, inventoryItems]);

  const formattedInventoryItems = React.useMemo(() => {
    return inventoryItems?.map((item) => {
      const { internalId, sku_options: skuOptions, quantity, name } = item;

      const isMskuItem = skuOptions && skuOptions?.length > 0;
      const pickedItem = pickedItems[internalId]?.uid;
      const pickedOption = pickedItem ? skuOptions?.find((option) => option.id === pickedItem) : undefined;

      if (!isMskuItem) {
        return item;
      }

      return pickedOption
        ? {
            ...pickedOption,
            quantity,
            id: internalId.concat(name),
          }
        : {
            ...item,
            id: internalId.concat(name),
            hint: MSKU_NOT_PICKED_ITEM_HINT,
            image_url: QuestionMarkImage,
            quantity,
            isScalable: false,
          };
    });
  }, [pickedItems, inventoryItems]);

  const outOfStockItems = React.useMemo(() => {
    // get a list of unique sku options
    const skuOptions = (inventoryItems ?? [])
      ?.filter((item) => !!item.sku_options)
      .map((msku) => msku.sku_options!)
      .flat()
      .reduce((acc, current) => {
        if (!acc.find((sku) => sku.id === current.id)) {
          acc?.push(current);
        }
        return acc;
      }, [] as ICampaignItem[]);

    return skuOptions
      .filter(({ id, count, preorder_allowed }) => {
        // get the quantity of picked items with the same id
        const quantity = pickedValues.reduce(
          (total, currentValue) => (currentValue?.uid === id ? total + 1 : total),
          0,
        );

        // item is disabled if it has no preorder and either a zero count or the quantity of picked items is equal to the count
        return !preorder_allowed && (!count || quantity === count);
      })
      .map((item) => item.id);
  }, [inventoryItems, pickedValues]);

  return (
    <Container className={mainStyles.AppWrapper}>
      <Row className={styles.mskuContainer}>
        <Col md={12} xl={8}>
          <Row className={styles.optionList}>
            <div className={styles.accordionContainer}>
              {inventoryItems?.map((item: ICampaignItem) => {
                const { internalId, sku_options } = item;
                return (
                  <Col className={cn(styles.option)} key={internalId}>
                    <div className={styles.itemContainer}>
                      <ItemPicker
                        item={item}
                        isExpandable={sku_options && sku_options?.length > 0}
                        expanded={expandedItem === internalId}
                        onExpand={setExpandedItem}
                        onSelect={(skuOption) => handleSelectItem({ [internalId!]: skuOption })}
                        selected={pickedItems[internalId!]}
                        disabledItems={outOfStockItems}
                      />
                    </div>
                  </Col>
                );
              })}
            </div>
          </Row>
        </Col>
        {!tablet && (
          <Col lg={4} className={styles.pickedCampaignContainer}>
            <CampaignItem
              isDigital={isDigital}
              className={styles.pickedCampaign}
              items={formattedInventoryItems}
              title={pickedCampaign?.name}
              description="What’s inside:"
            />
          </Col>
        )}
      </Row>
      <Row className={styles.continueRow}>
        <SubmitButton onSubmit={handleContinue} disabled={isStepDisabled}>
          Continue
        </SubmitButton>
      </Row>
    </Container>
  );
};

export default PickMsku;
