import cn from 'classnames';
import { FieldAttributes } from 'formik';
import * as React from 'react';

import styles from './RadioButton.module.scss';

interface IProps extends FieldAttributes<any> {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  label?: string | React.ReactNode;
  containerClassName?: string;
  value?: string;
  defaultValue?: string;
  disabled?: boolean;
  name?: string;
  children?: React.ReactNode;
}

const RadioButton: React.FC<IProps> = ({
  className,
  label,
  containerClassName,
  value,
  defaultValue,
  name,
  disabled,
  field,
  onChange,
}: IProps) => {
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (field?.onChange(e)) {
        field.onChange(e);
      }
      if (onChange) {
        onChange(e);
      }
    },
    [field, onChange],
  );

  return (
    <React.Fragment>
      <input
        id={name || field?.name}
        name={name || field?.name}
        type="radio"
        value={defaultValue}
        checked={value === field?.value || value === defaultValue}
        className={cn(className, styles.input)}
        disabled={disabled}
        onChange={handleChange}
      />
      <label htmlFor={name || field?.name} className={cn(styles.radioButton, containerClassName)}>
        {label}
      </label>
    </React.Fragment>
  );
};

export default RadioButton;
