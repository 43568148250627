import { RouterState } from 'connected-react-router';
import { ErrorCodeEnum } from '../constants/errors';
import { ALLOWED_RECIPIENT_UID, KEY, ONE_LINK_ID, ORDER_ID } from '../constants/shipping';
import {
  ICustomItemsInformation,
  ICustomizableItem,
  ICustomizableStoredItem,
  IMskuInformation,
  IOrgInfo,
  IPickedMskuItem,
  IPYGInformation,
  IReceiverInfo,
  ISenderInfo,
  IWhiteLabeling,
  TInitAddress,
} from './api';
import { ICampaign, ICampaignItem } from './pyg';
import { IInitKeys } from './shipping';

export enum ActionsEnum {
  InitApp = 'init_app',
  SetKeys = 'set_keys',
  UpdateKeys = 'update_keys',
  ClearKeys = 'set_keys',

  GetFormDataRequest = 'get_form_data_request',
  GetFormDataFailure = 'get_form_data_failure',
  GetFormDataSuccess = 'get_form_data_success',

  UpdateRequest = 'update_request',
  UpdateFailure = 'update_failure',
  UpdateSuccess = 'update_success',

  SubmitPYG = 'submit_pick_your_gift',
  SubmitMSKU = 'submit_pick_items',

  SetCustomizableItems = 'set_customizable_items',
  SubmitCustomizedItems = 'submit_customized_items',

  SetIsFormSubmitted = 'set_is_form_submitted',
  SetPassword = 'set_password',
  SetWhiteLabeling = 'set_white_labeling',
  Set2FATo = 'set_2fa_to',
  Set2FACode = 'set_2fa_code',

  AuthorizeReceiverRequest = 'authorize_receiver_request',
}

export interface IAction<P = unknown, M = null> {
  type: ActionsEnum;
  payload?: P;
  meta?: M;
}

export interface IError {
  title?: string;
  message?: string;
  error_code?: ErrorCodeEnum;
}

export interface IKeysState extends IInitKeys {
  isInitialized: boolean;
}

export interface IInitState {
  isLoading: boolean;
  error: IError | null;
  org: IOrgInfo | null;
  whiteLabeling: IWhiteLabeling | null;
  sender: ISenderInfo | null;
  address: TInitAddress | null;
  campaigns: ICampaign[] | null;
  items: ICampaignItem[] | null;
  isDigital: boolean;
  isFormSubmitted: boolean;
  password: string | null;
  send2FATo: string | null;
  code: string | null;
}

export interface IFormState {
  address: IReceiverInfo | null;
  pickedCampaignId: string | null;
  pickedItems: { [id: string]: IPickedMskuItem } | null;
  customItems: { [id: string]: ICustomizableStoredItem } | null;
}

export interface IAppState {
  keys: IKeysState;
  init: IInitState;
  form: IFormState;
  router: RouterState;
  shell: IShellState;
}
export interface IShellState {
  build: string | undefined;
  commit: string | undefined;
}

/* Action interfaces */
export interface ISetKeysPayload {
  [ORDER_ID]?: string;
  [KEY]?: string;
  [ONE_LINK_ID]?: string;
  [ALLOWED_RECIPIENT_UID]?: string;
  resolve?: (value: PromiseLike<unknown> | unknown) => void;
  reject?: (e?: unknown) => void;
}

export interface IInitSuccessPayload {
  org_info: IOrgInfo;
  sender_info: ISenderInfo;
  receiver_info: TInitAddress;
  ds_customized_texts: IWhiteLabeling;
  pick_campaigns: null | ICampaign[];
  inventory_items: null | ICampaignItem[];
  is_digital?: boolean;
}

export interface ISetWhiteLabelingPayload {
  org_info: IOrgInfo;
  sender_info: ISenderInfo;
  ds_customized_texts: IWhiteLabeling;
}

export interface ISubmitPYGPayload {
  campaignId: string;
  resolve: (route: string) => void;
}

export interface ISubmitCustomItemsPayload {
  [internalId: string]: string;
}

export interface ISubmitMSKUPayload {
  pickedItems: { [p: string]: IPickedMskuItem };
  resolve: (route: string) => void;
}

export interface ICustomizableItemPayload {
  [p: string]: ICustomizableItem | null;
}

export interface ISubmitAddressPayload {
  values: Partial<IReceiverInfo>;
  resolve?: (value: PromiseLike<unknown> | unknown) => void;
  reject?: (e?: unknown) => void;
}

export interface IAuthorizeReceiverPayload {
  action: AuthActionEnum;
  to: string | null;
  code?: string;
  one_link_id?: string;
  id?: string;
  resolve?: (value: PromiseLike<unknown> | unknown) => void;
  reject?: (e?: unknown) => void;
}

export interface IAuthorizeReceiverSuccessPayload {
  id: string;
}

export enum AuthActionEnum {
  Init = 'init',
  Verify = 'verify',
}

export type TUpdateRequestBody = Partial<
  IReceiverInfo & IInitKeys & IPYGInformation & IMskuInformation & ICustomItemsInformation
>;
