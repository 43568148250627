import cn from 'classnames';
import { FormikHelpers, useFormik } from 'formik';
import * as React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';

import { CODE, SEND_2FA_TO } from '../../../constants/shipping';
import { set2FACode, set2FATo } from '../../../store/actions/init';
import { select2FATo } from '../../../store/selectors/init';
import { IAuthInfo } from '../../../types/shipping';
import { isPhoneNumber, isValidEmail } from '../../../util/validation';
import Input from '../../Input/Input';
import SubmitButton from '../../SubmitButton/SubmitButton';

import { ReactComponent as LockIcon } from '../../../assets/icon-lock.svg';

import 'react-phone-number-input/style.css';
import styles from './TwoFAForm.module.scss';

interface IProps {
  initialValues: IAuthInfo;
  handleSubmit: (formikHelpers: FormikHelpers<IAuthInfo>) => Promise<void>;
  allowedRecipientUid?: string;
}

const TwoFAForm = ({ initialValues, handleSubmit, allowedRecipientUid }: IProps) => {
  const dispatch = useDispatch();

  const send2FATo = useSelector(select2FATo);

  const form = useFormik<IAuthInfo>({
    initialValues,
    onSubmit: (values: IAuthInfo, actions: FormikHelpers<IAuthInfo>) => {
      if (values) {
        handleSubmit(actions);
      }
    },
  });

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      form.handleChange(e);
      allowedRecipientUid ? dispatch(set2FACode(e.target.value)) : dispatch(set2FATo(e.target.value));
    },
    [form.handleChange, allowedRecipientUid, dispatch],
  );

  const isPhoneInput = React.useMemo(() => {
    return send2FATo && isPhoneNumber(send2FATo);
  }, [send2FATo]);

  const isValidInput = React.useMemo(() => {
    return isPhoneInput ? isValidPhoneNumber(form.values?.[SEND_2FA_TO]!) : isValidEmail(form.values?.[SEND_2FA_TO]!);
  }, [form.values, isPhoneInput]);

  const isContinueButtonDisabled = React.useMemo(() => {
    return (
      form.isSubmitting ||
      !form.values?.[SEND_2FA_TO] ||
      !isValidInput ||
      (!!allowedRecipientUid && !form.values?.[CODE])
    );
  }, [form.isSubmitting, form.values?.[SEND_2FA_TO], form.values?.[CODE], isValidInput, allowedRecipientUid]);

  const render2FAToInput = React.useMemo(() => {
    return isPhoneInput ? (
      <PhoneInput
        international
        value={form.values?.[SEND_2FA_TO] || ''}
        onChange={(value) => {
          form.setFieldValue(SEND_2FA_TO, value);
          dispatch(set2FATo(value!));
        }}
        className={styles.input}
        autoFocus
      />
    ) : (
      <Input
        isRequired
        type="text"
        name={SEND_2FA_TO}
        onChange={handleChange}
        value={form.values?.[SEND_2FA_TO] || ''}
        className={styles.input}
        autoFocus
      />
    );
  }, [dispatch, handleChange, isPhoneInput, form.values?.[SEND_2FA_TO], form.setFieldValue]);

  return (
    <Form className={cn(styles.passwordForm)}>
      <Row className={styles.row}>
        {allowedRecipientUid ? (
          <span className={styles.label}>Enter verification code</span>
        ) : (
          <span className={styles.label}>Enter your email address or phone number</span>
        )}
        <Col className={styles.inputWrapper}>
          {allowedRecipientUid ? (
            <Input
              isRequired
              type="text"
              name={CODE}
              onChange={handleChange}
              value={form.values?.[CODE] || ''}
              className={styles.input}
            />
          ) : (
            <>{render2FAToInput}</>
          )}
          <LockIcon />
        </Col>
      </Row>
      <Row className={styles.row}>
        <SubmitButton className={styles.submit} onSubmit={form.handleSubmit} disabled={isContinueButtonDisabled}>
          Continue
        </SubmitButton>
      </Row>
    </Form>
  );
};

export default TwoFAForm;
