export const routes = {
  root: '/',
  address: '/address',
  success: '/success',
  pyg: '/pick-your-gift',
  pickMsku: '/customize-options',
  customizeItems: '/customize-items',
  error: '/error',
  authentication: '/authentication',
};

export enum IRouterActions {
  BACK = 'POP',
}

export const FLOW_ROUTES = [routes.pyg, routes.pickMsku, routes.customizeItems, routes.address];

export const RESULT_ROUTES = [routes.success, routes.error];
