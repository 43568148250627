import cn from 'classnames';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { selectAccentColor } from '../../store/selectors/init';
import { isBrightColor, isLightColor } from '../../util/ui';

import styles from './SubmitButton.module.scss';

interface IProps<T> {
  className?: string;
  onSubmit?: React.MouseEventHandler<HTMLButtonElement> | ((e?: React.FormEvent<HTMLFormElement> | undefined) => void);
  children: React.ReactNode;
  disabled?: boolean;
  type?: 'submit' | 'button' | 'reset';
}

const SubmitButton = <T, _>({ children, className, disabled, onSubmit, type = 'submit' }: IProps<T>) => {
  const accentColor = useSelector(selectAccentColor);
  const isLight = React.useMemo(() => isLightColor(accentColor), [accentColor]);
  const isBright = React.useMemo(() => isBrightColor(accentColor), [accentColor]);

  return (
    <Button
      type={type}
      className={cn(className, styles.button, { [styles.dark]: !isLight }, { [styles.light]: isBright })}
      onClick={onSubmit as React.MouseEventHandler<HTMLButtonElement>}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default SubmitButton;
