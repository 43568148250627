import * as React from 'react';

import { getStyleVariableValue } from '../util/helpers';

const XS = getStyleVariableValue('--breakpoint-xs');
const MD = getStyleVariableValue('--breakpoint-md');
const LG = getStyleVariableValue('--breakpoint-lg');
const XL = getStyleVariableValue('--breakpoint-xl');
const XXl = getStyleVariableValue('--breakpoint-xl');
const XXXl = getStyleVariableValue('--breakpoint-xl');

const useWindowSize = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  const handleResize = () => {
    setWidth(window.innerWidth!);
    setHeight(window.innerHeight!);
  };

  React.useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const mobile = React.useMemo(() => (width ? width <= parseInt(XS, 10) : false), [width]);
  const medium = React.useMemo(() => (width ? width <= parseInt(MD, 10) : false), [width]);
  const smallTablet = React.useMemo(() => (width ? width <= parseInt(LG, 10) : false), [width]);
  const tablet = React.useMemo(() => (width ? width <= parseInt(XL, 10) : false), [width]);
  const desktop = React.useMemo(
    () => (width ? width > parseInt(XL, 10) && width <= parseInt(XXl, 10) : false),
    [width],
  );
  const HD = React.useMemo(() => (width ? width <= parseInt(XXl, 10) : false), [width]);
  const laptop = React.useMemo(() => (width ? width <= parseInt(XXXl, 10) : false), [width]);
  const fullHD = React.useMemo(() => (width ? width > parseInt(XXXl, 10) : false), [width]);

  return {
    width,
    height,
    mobile,
    medium,
    smallTablet,
    tablet,
    desktop,
    HD,
    laptop,
    fullHD,
  };
};

export default useWindowSize;
