import cn from 'classnames';
import { FormikHelpers, useFormik } from 'formik';
import * as React from 'react';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import FixedAddress from '../../components/FixedAddress/FixedAddress';
import FormDigital from '../../components/forms/FormDigital';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import { trackFormSubmit } from '../../constants/analytics';
import { FORMS_ENUM } from '../../constants/shipping';
import useAnalytics from '../../hooks/useAnalytics';
import { selectIsDigital } from '../../store/selectors/init';
import { IDigitalReceiverInfo, IFixedAddress } from '../../types/api';
import { digitalFormValidation as validationSchema } from '../../util/validation';

import styles from './ShippingForm.module.scss';

interface IProps {
  initialValues: IDigitalReceiverInfo;
  onSubmit: (
    values: Partial<IDigitalReceiverInfo>,
    formikHelpers: FormikHelpers<Partial<IDigitalReceiverInfo>>,
  ) => Promise<void>;
  address?: IFixedAddress;
}

const DigitalShippingForm = ({ initialValues, onSubmit, address }: IProps) => {
  const { trackEvent } = useAnalytics();
  const isDigital = useSelector(selectIsDigital);

  const form = useFormik<Partial<IDigitalReceiverInfo>>({
    initialValues,
    onSubmit: (values: Partial<IDigitalReceiverInfo>, actions: FormikHelpers<Partial<IDigitalReceiverInfo>>) => {
      const castedValues = validationSchema.cast(values);
      if (castedValues) {
        onSubmit(castedValues, actions).then(() => trackEvent(trackFormSubmit(FORMS_ENUM.Digital)));
      }
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validationSchema,
  });

  return (
    <div className={styles.formWrapper}>
      <FormDigital
        title={address && !isDigital ? 'Recipient Info' : ''}
        form={form}
        className={cn(styles.form, styles.digital)}
      />
      <Col className={styles.requiredMessage}>
        <span className={styles.asterisk}>*</span> Required fields
      </Col>
      {address && !isDigital && <FixedAddress address={address} />}
      <Col className={styles.actions}>
        <SubmitButton onSubmit={() => form.handleSubmit()} disabled={form.isSubmitting}>
          Confirm
        </SubmitButton>
      </Col>
    </div>
  );
};

export default DigitalShippingForm;
