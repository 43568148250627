import cn from 'classnames';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { selectBaseColor } from '../../store/selectors/init';
import { isLightColor } from '../../util/ui';

import styles from './Footer.module.scss';

export interface IFooterProps {
  className?: string;
}

const Footer: React.FC<IFooterProps> = ({ className }) => {
  const backgroundColor = useSelector(selectBaseColor);

  const isLightBackground = React.useMemo(() => {
    return isLightColor(backgroundColor);
  }, [backgroundColor]);

  return (
    <Container
      fluid
      className={cn(styles.footerContainer, isLightBackground && styles.lightBackground, className)}
      style={{ backgroundColor }}
    >
      <Container>
        <Row>
          <Col xs={12} className="d-flex justify-content-center">
            Powered by&nbsp;
            <a href="https://imprintengine.com" target="_blank" rel="noopener noreferrer">
              imprintengine.com
            </a>
            .&nbsp;All rights reserved&nbsp;
            <a href="https://imprintengine.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
              Imprint Engine Privacy Policy
            </a>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
