import { ActionsEnum, IAction, IKeysState, ISetKeysPayload } from '../../types/store';

const initialState: IKeysState = {
  isInitialized: false,
};

const reducer = (state: IKeysState = initialState, action: IAction): IKeysState => {
  switch (action.type) {
    case ActionsEnum.SetKeys:
      const { order_id, key, one_link_id, allowed_recipient_uid } = (action.payload as ISetKeysPayload) || {};
      return { isInitialized: true, order_id, key, one_link_id, allowed_recipient_uid };
    case ActionsEnum.UpdateKeys:
      return { ...state, ...(action.payload as IKeysState) };
    case ActionsEnum.ClearKeys:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
