import cn from 'classnames';
import * as React from 'react';

import styles from './HtmlView.module.scss';

interface IProps {
  containerClassname?: string;
  className?: string;
  html: string;
  children?: React.ReactNode;
}

const HtmlView: React.FC<IProps> = ({ className, containerClassname, html }: IProps) => {
  return (
    <div className={cn(styles.container, containerClassname)}>
      <div className={cn(styles.html, className)} dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};

export default HtmlView;
