import { SHIPPING_PAGE } from '../constants/analytics';
import { IAnalyticsEvent } from '../types/analytics';

export const page = (pageName?: string) => ({
  hitType: 'pageview',
  page: pageName,
});

export const event = ({ eventAction, eventLabel, eventValue }: IAnalyticsEvent) => ({
  hitType: 'event',
  eventCategory: SHIPPING_PAGE,
  ...(eventAction ? { eventAction } : {}),
  ...(eventLabel ? { eventLabel } : {}),
  ...(eventValue ? { eventValue } : {}),
});

export const exception = (error: any) => ({
  hitType: 'exception',
  exDescription: JSON.stringify(error),
  isFatal: true,
});

export const apiException = (error: any) => ({
  hitType: 'exception',
  exDescription: JSON.stringify(error),
  isFatal: false,
});
