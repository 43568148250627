import cn from 'classnames';
import { FormikHelpers } from 'formik';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import BrandLogo from '../../components/BrandLogo/BrandLogo';
import PasswordForm from '../../components/forms/PasswordForm/PasswordForm';
import TwoFAForm from '../../components/forms/TwoFAForm/TwoFAForm';

import { authorizeReceiverRequest, getFormDataRequestAction } from '../../store/actions/init';
import { selectOrgLogo } from '../../store/selectors/derived';
import { select2FATo, selectCode, selectErrorCode, selectResultScreenColor } from '../../store/selectors/init';
import { selectAllowedRecipientUid, selectInitKeys } from '../../store/selectors/keys';
import { IAuthInfo } from '../../types/shipping';
import { AuthActionEnum } from '../../types/store';
import { isLightColor } from '../../util/ui';
import { isPhoneNumber } from '../../util/validation';

import LockImage from '../../assets/image-lock.svg';

import { ErrorCodeEnum } from '../../constants/errors';
import styles from './Authentication.module.scss';

const sendTo = (inputValue: string | null) => {
  return inputValue && isPhoneNumber(inputValue) ? 'phone' : 'email address';
};

const Authentication = () => {
  const dispatch = useDispatch();

  const errorCode = useSelector(selectErrorCode);
  const keys = useSelector(selectInitKeys);
  const backgroundColor = useSelector(selectResultScreenColor);
  const orgLogo = useSelector(selectOrgLogo);

  const allowedRecipientUid = useSelector(selectAllowedRecipientUid);
  const send2FATo = useSelector(select2FATo);
  const code = useSelector(selectCode);

  const isBackgroundLight = React.useMemo(() => {
    return isLightColor(backgroundColor);
  }, [backgroundColor]);

  const handleSubmit = React.useCallback(
    (actions: FormikHelpers<IAuthInfo>) => {
      return new Promise((resolve, reject) => {
        dispatch(getFormDataRequestAction({ ...keys, resolve, reject }));
      })
        .then(() => {
          actions.setSubmitting(false);
        })
        .catch((e: any) => {
          actions.setSubmitting(false);
          const { error_code } = e;

          if (error_code === ErrorCodeEnum.PasswordRequired) {
            toast.error('Wrong password');
          }
        });
    },
    [dispatch],
  );

  const handle2FASubmit = React.useCallback(
    (actions: FormikHelpers<IAuthInfo>) => {
      const initParams = {
        action: AuthActionEnum.Init,
        one_link_id: keys.one_link_id,
        to: send2FATo,
      };

      const verifyParams = {
        action: AuthActionEnum.Verify,
        one_link_id: keys.one_link_id,
        to: send2FATo,
        code,
        id: allowedRecipientUid,
      };

      const params = allowedRecipientUid ? verifyParams : initParams;

      return new Promise((resolve, reject) => {
        dispatch(authorizeReceiverRequest({ ...params, resolve, reject }));
      })
        .then(() => {
          actions.setSubmitting(false);
        })
        .catch((e: any) => {
          const { message } = e;
          toast.error(message);
          actions.setSubmitting(false);
        });
    },
    [allowedRecipientUid, code, dispatch, keys.one_link_id, send2FATo],
  );

  const renderPasswordForm = React.useMemo(() => {
    return (
      <>
        <h1 className={styles.title}>Please enter the password to view this send.</h1>
        <PasswordForm handleSubmit={handleSubmit} initialValues={{ password: '' }} />
      </>
    );
  }, [handleSubmit]);

  const render2FAForm = React.useMemo(() => {
    const title = allowedRecipientUid
      ? `We sent a message with the verification code to the ${sendTo(send2FATo)} you entered.`
      : 'Please confirm your information to view this send.';

    return (
      <>
        <h1 className={styles.title}>{title}</h1>
        {allowedRecipientUid && <p>It may take a few minutes for the code to arrive.</p>}
        <TwoFAForm
          handleSubmit={handle2FASubmit}
          initialValues={{ send2FATo: '', code: '' }}
          allowedRecipientUid={allowedRecipientUid}
        />
      </>
    );
  }, [allowedRecipientUid, handle2FASubmit, send2FATo]);

  const renderForm = React.useMemo(() => {
    return errorCode === ErrorCodeEnum.RequiredTwoFA ? render2FAForm : renderPasswordForm;
  }, [errorCode, render2FAForm, renderPasswordForm]);

  return (
    <div className={styles.container} style={{ backgroundColor }}>
      <Container as="header" className={styles.header}>
        <BrandLogo image={orgLogo} />
      </Container>
      <Container as="main" className={cn(styles.main, { [styles.lightBackground]: isBackgroundLight })}>
        <Row className={styles.content}>
          <Col xs={12} md={6} className={styles.text}>
            {renderForm}
          </Col>
          <Col xs={12} md={6} className={styles.focus}>
            <img className={styles.focusImage} src={LockImage} alt="" />
          </Col>
        </Row>
      </Container>
      <Container as="footer" className={cn(styles.footer, { [styles.lightBackground]: isBackgroundLight })}>
        <span className={styles.powered}>
          Powered by{' '}
          <a href="https://imprintengine.com" className={styles.footerLink}>
            Imprint Engine
          </a>
          . All rights reserved
        </span>
      </Container>
    </div>
  );
};

export default Authentication;
