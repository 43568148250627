import {
  ActionsEnum,
  IAuthorizeReceiverPayload,
  IInitSuccessPayload,
  ISetKeysPayload,
  ISetWhiteLabelingPayload,
} from '../../types/store';

export const initApp = (payload?: string) => ({
  type: ActionsEnum.InitApp,
  payload,
});

export const getFormDataRequestAction = (payload: ISetKeysPayload) => ({
  type: ActionsEnum.GetFormDataRequest,
  payload,
});
export const getFormDataFailureAction = (payload: object | unknown) => ({
  type: ActionsEnum.GetFormDataFailure,
  payload,
});
export const getFormDataSuccessAction = (payload: IInitSuccessPayload) => ({
  type: ActionsEnum.GetFormDataSuccess,
  payload,
});
export const setIsFormSubmitted = (payload: boolean) => ({
  type: ActionsEnum.SetIsFormSubmitted,
  payload,
});
export const setPassword = (payload: string) => ({
  type: ActionsEnum.SetPassword,
  payload,
});
export const set2FATo = (payload: string) => ({
  type: ActionsEnum.Set2FATo,
  payload,
});
export const set2FACode = (payload: string) => ({
  type: ActionsEnum.Set2FACode,
  payload,
});
export const setWhiteLabeling = (payload: ISetWhiteLabelingPayload) => ({
  type: ActionsEnum.SetWhiteLabeling,
  payload,
});
export const authorizeReceiverRequest = (payload: IAuthorizeReceiverPayload) => ({
  type: ActionsEnum.AuthorizeReceiverRequest,
  payload,
});
