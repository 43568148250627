import { ICountryItem } from '../types/shell';

export const USA = {
  name: 'United States of America (the)',
  two_digit_code: 'US',
  three_digit_code: 'USA',
  numeric: 840,
};

export const countryList: ICountryItem[] = [
  {
    name: 'Afghanistan',
    two_digit_code: 'AF',
    three_digit_code: 'AFG',
    numeric: 4,
  },
  {
    name: 'Åland Islands',
    two_digit_code: 'AX',
    three_digit_code: 'ALA',
    numeric: 248,
  },
  {
    name: 'Albania',
    two_digit_code: 'AL',
    three_digit_code: 'ALB',
    numeric: 8,
  },
  {
    name: 'Algeria',
    two_digit_code: 'DZ',
    three_digit_code: 'DZA',
    numeric: 12,
  },
  {
    name: 'American Samoa',
    two_digit_code: 'AS',
    three_digit_code: 'ASM',
    numeric: 16,
  },
  {
    name: 'Andorra',
    two_digit_code: 'AD',
    three_digit_code: 'AND',
    numeric: 20,
  },
  {
    name: 'Angola',
    two_digit_code: 'AO',
    three_digit_code: 'AGO',
    numeric: 24,
  },
  {
    name: 'Anguilla',
    two_digit_code: 'AI',
    three_digit_code: 'AIA',
    numeric: 660,
  },
  {
    name: 'Antarctica',
    two_digit_code: 'AQ',
    three_digit_code: 'ATA',
    numeric: 10,
  },
  {
    name: 'Antigua and Barbuda',
    two_digit_code: 'AG',
    three_digit_code: 'ATG',
    numeric: 28,
  },
  {
    name: 'Argentina',
    two_digit_code: 'AR',
    three_digit_code: 'ARG',
    numeric: 32,
  },
  {
    name: 'Armenia',
    two_digit_code: 'AM',
    three_digit_code: 'ARM',
    numeric: 51,
  },
  {
    name: 'Aruba',
    two_digit_code: 'AW',
    three_digit_code: 'ABW',
    numeric: 533,
  },
  {
    name: 'Australia',
    two_digit_code: 'AU',
    three_digit_code: 'AUS',
    numeric: 36,
  },
  {
    name: 'Austria',
    two_digit_code: 'AT',
    three_digit_code: 'AUT',
    numeric: 40,
  },
  {
    name: 'Azerbaijan',
    two_digit_code: 'AZ',
    three_digit_code: 'AZE',
    numeric: 31,
  },
  {
    name: 'Bahamas (the)',
    two_digit_code: 'BS',
    three_digit_code: 'BHS',
    numeric: 44,
  },
  {
    name: 'Bahrain',
    two_digit_code: 'BH',
    three_digit_code: 'BHR',
    numeric: 48,
  },
  {
    name: 'Bangladesh',
    two_digit_code: 'BD',
    three_digit_code: 'BGD',
    numeric: 50,
  },
  {
    name: 'Barbados',
    two_digit_code: 'BB',
    three_digit_code: 'BRB',
    numeric: 52,
  },
  {
    name: 'Belarus',
    two_digit_code: 'BY',
    three_digit_code: 'BLR',
    numeric: 112,
  },
  {
    name: 'Belgium',
    two_digit_code: 'BE',
    three_digit_code: 'BEL',
    numeric: 56,
  },
  {
    name: 'Belize',
    two_digit_code: 'BZ',
    three_digit_code: 'BLZ',
    numeric: 84,
  },
  {
    name: 'Benin',
    two_digit_code: 'BJ',
    three_digit_code: 'BEN',
    numeric: 204,
  },
  {
    name: 'Bermuda',
    two_digit_code: 'BM',
    three_digit_code: 'BMU',
    numeric: 60,
  },
  {
    name: 'Bhutan',
    two_digit_code: 'BT',
    three_digit_code: 'BTN',
    numeric: 64,
  },
  {
    name: 'Bolivia (Plurinational State of)',
    two_digit_code: 'BO',
    three_digit_code: 'BOL',
    numeric: 68,
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    two_digit_code: 'BQ',
    three_digit_code: 'BES',
    numeric: 535,
  },
  {
    name: 'Bosnia and Herzegovina',
    two_digit_code: 'BA',
    three_digit_code: 'BIH',
    numeric: 70,
  },
  {
    name: 'Botswana',
    two_digit_code: 'BW',
    three_digit_code: 'BWA',
    numeric: 72,
  },
  {
    name: 'Bouvet Island',
    two_digit_code: 'BV',
    three_digit_code: 'BVT',
    numeric: 74,
  },
  {
    name: 'Brazil',
    two_digit_code: 'BR',
    three_digit_code: 'BRA',
    numeric: 76,
  },
  {
    name: 'British Indian Ocean Territory (the)',
    two_digit_code: 'IO',
    three_digit_code: 'IOT',
    numeric: 86,
  },
  {
    name: 'Brunei Darussalam',
    two_digit_code: 'BN',
    three_digit_code: 'BRN',
    numeric: 96,
  },
  {
    name: 'Bulgaria',
    two_digit_code: 'BG',
    three_digit_code: 'BGR',
    numeric: 100,
  },
  {
    name: 'Burkina Faso',
    two_digit_code: 'BF',
    three_digit_code: 'BFA',
    numeric: 854,
  },
  {
    name: 'Burundi',
    two_digit_code: 'BI',
    three_digit_code: 'BDI',
    numeric: 108,
  },
  {
    name: 'Cabo Verde',
    two_digit_code: 'CV',
    three_digit_code: 'CPV',
    numeric: 132,
  },
  {
    name: 'Cambodia',
    two_digit_code: 'KH',
    three_digit_code: 'KHM',
    numeric: 116,
  },
  {
    name: 'Cameroon',
    two_digit_code: 'CM',
    three_digit_code: 'CMR',
    numeric: 120,
  },
  {
    name: 'Canada',
    two_digit_code: 'CA',
    three_digit_code: 'CAN',
    numeric: 124,
  },
  {
    name: 'Cayman Islands (the)',
    two_digit_code: 'KY',
    three_digit_code: 'CYM',
    numeric: 136,
  },
  {
    name: 'Central African Republic (the)',
    two_digit_code: 'CF',
    three_digit_code: 'CAF',
    numeric: 140,
  },
  {
    name: 'Chad',
    two_digit_code: 'TD',
    three_digit_code: 'TCD',
    numeric: 148,
  },
  {
    name: 'Chile',
    two_digit_code: 'CL',
    three_digit_code: 'CHL',
    numeric: 152,
  },
  {
    name: 'China',
    two_digit_code: 'CN',
    three_digit_code: 'CHN',
    numeric: 156,
  },
  {
    name: 'Christmas Island',
    two_digit_code: 'CX',
    three_digit_code: 'CXR',
    numeric: 162,
  },
  {
    name: 'Cocos (Keeling) Islands (the)',
    two_digit_code: 'CC',
    three_digit_code: 'CCK',
    numeric: 166,
  },
  {
    name: 'Colombia',
    two_digit_code: 'CO',
    three_digit_code: 'COL',
    numeric: 170,
  },
  {
    name: 'Comoros (the)',
    two_digit_code: 'KM',
    three_digit_code: 'COM',
    numeric: 174,
  },
  {
    name: 'Congo (the Democratic Republic of the)',
    two_digit_code: 'CD',
    three_digit_code: 'COD',
    numeric: 180,
  },
  {
    name: 'Congo (the)',
    two_digit_code: 'CG',
    three_digit_code: 'COG',
    numeric: 178,
  },
  {
    name: 'Cook Islands (the)',
    two_digit_code: 'CK',
    three_digit_code: 'COK',
    numeric: 184,
  },
  {
    name: 'Costa Rica',
    two_digit_code: 'CR',
    three_digit_code: 'CRI',
    numeric: 188,
  },
  {
    name: "Côte d'Ivoire",
    two_digit_code: 'CI',
    three_digit_code: 'CIV',
    numeric: 384,
  },
  {
    name: 'Croatia',
    two_digit_code: 'HR',
    three_digit_code: 'HRV',
    numeric: 191,
  },
  {
    name: 'Cuba',
    two_digit_code: 'CU',
    three_digit_code: 'CUB',
    numeric: 192,
  },
  {
    name: 'Curaçao',
    two_digit_code: 'CW',
    three_digit_code: 'CUW',
    numeric: 531,
  },
  {
    name: 'Cyprus',
    two_digit_code: 'CY',
    three_digit_code: 'CYP',
    numeric: 196,
  },
  {
    name: 'Czechia',
    two_digit_code: 'CZ',
    three_digit_code: 'CZE',
    numeric: 203,
  },
  {
    name: 'Denmark',
    two_digit_code: 'DK',
    three_digit_code: 'DNK',
    numeric: 208,
  },
  {
    name: 'Djibouti',
    two_digit_code: 'DJ',
    three_digit_code: 'DJI',
    numeric: 262,
  },
  {
    name: 'Dominica',
    two_digit_code: 'DM',
    three_digit_code: 'DMA',
    numeric: 212,
  },
  {
    name: 'Dominican Republic (the)',
    two_digit_code: 'DO',
    three_digit_code: 'DOM',
    numeric: 214,
  },
  {
    name: 'Ecuador',
    two_digit_code: 'EC',
    three_digit_code: 'ECU',
    numeric: 218,
  },
  {
    name: 'Egypt',
    two_digit_code: 'EG',
    three_digit_code: 'EGY',
    numeric: 818,
  },
  {
    name: 'El Salvador',
    two_digit_code: 'SV',
    three_digit_code: 'SLV',
    numeric: 222,
  },
  {
    name: 'Equatorial Guinea',
    two_digit_code: 'GQ',
    three_digit_code: 'GNQ',
    numeric: 226,
  },
  {
    name: 'Eritrea',
    two_digit_code: 'ER',
    three_digit_code: 'ERI',
    numeric: 232,
  },
  {
    name: 'Estonia',
    two_digit_code: 'EE',
    three_digit_code: 'EST',
    numeric: 233,
  },
  {
    name: 'Eswatini',
    two_digit_code: 'SZ',
    three_digit_code: 'SWZ',
    numeric: 748,
  },
  {
    name: 'Ethiopia',
    two_digit_code: 'ET',
    three_digit_code: 'ETH',
    numeric: 231,
  },
  {
    name: 'Falkland Islands (the) [Malvinas]',
    two_digit_code: 'FK',
    three_digit_code: 'FLK',
    numeric: 238,
  },
  {
    name: 'Faroe Islands (the)',
    two_digit_code: 'FO',
    three_digit_code: 'FRO',
    numeric: 234,
  },
  {
    name: 'Fiji',
    two_digit_code: 'FJ',
    three_digit_code: 'FJI',
    numeric: 242,
  },
  {
    name: 'Finland',
    two_digit_code: 'FI',
    three_digit_code: 'FIN',
    numeric: 246,
  },
  {
    name: 'France',
    two_digit_code: 'FR',
    three_digit_code: 'FRA',
    numeric: 250,
  },
  {
    name: 'French Guiana',
    two_digit_code: 'GF',
    three_digit_code: 'GUF',
    numeric: 254,
  },
  {
    name: 'French Polynesia',
    two_digit_code: 'PF',
    three_digit_code: 'PYF',
    numeric: 258,
  },
  {
    name: 'French Southern Territories (the)',
    two_digit_code: 'TF',
    three_digit_code: 'ATF',
    numeric: 260,
  },
  {
    name: 'Gabon',
    two_digit_code: 'GA',
    three_digit_code: 'GAB',
    numeric: 266,
  },
  {
    name: 'Gambia (the)',
    two_digit_code: 'GM',
    three_digit_code: 'GMB',
    numeric: 270,
  },
  {
    name: 'Georgia',
    two_digit_code: 'GE',
    three_digit_code: 'GEO',
    numeric: 268,
  },
  {
    name: 'Germany',
    two_digit_code: 'DE',
    three_digit_code: 'DEU',
    numeric: 276,
  },
  {
    name: 'Ghana',
    two_digit_code: 'GH',
    three_digit_code: 'GHA',
    numeric: 288,
  },
  {
    name: 'Gibraltar',
    two_digit_code: 'GI',
    three_digit_code: 'GIB',
    numeric: 292,
  },
  {
    name: 'Greece',
    two_digit_code: 'GR',
    three_digit_code: 'GRC',
    numeric: 300,
  },
  {
    name: 'Greenland',
    two_digit_code: 'GL',
    three_digit_code: 'GRL',
    numeric: 304,
  },
  {
    name: 'Grenada',
    two_digit_code: 'GD',
    three_digit_code: 'GRD',
    numeric: 308,
  },
  {
    name: 'Guadeloupe',
    two_digit_code: 'GP',
    three_digit_code: 'GLP',
    numeric: 312,
  },
  {
    name: 'Guam',
    two_digit_code: 'GU',
    three_digit_code: 'GUM',
    numeric: 316,
  },
  {
    name: 'Guatemala',
    two_digit_code: 'GT',
    three_digit_code: 'GTM',
    numeric: 320,
  },
  {
    name: 'Guernsey',
    two_digit_code: 'GG',
    three_digit_code: 'GGY',
    numeric: 831,
  },
  {
    name: 'Guinea',
    two_digit_code: 'GN',
    three_digit_code: 'GIN',
    numeric: 324,
  },
  {
    name: 'Guinea-Bissau',
    two_digit_code: 'GW',
    three_digit_code: 'GNB',
    numeric: 624,
  },
  {
    name: 'Guyana',
    two_digit_code: 'GY',
    three_digit_code: 'GUY',
    numeric: 328,
  },
  {
    name: 'Haiti',
    two_digit_code: 'HT',
    three_digit_code: 'HTI',
    numeric: 332,
  },
  {
    name: 'Heard Island and McDonald Islands',
    two_digit_code: 'HM',
    three_digit_code: 'HMD',
    numeric: 334,
  },
  {
    name: 'Holy See (the)',
    two_digit_code: 'VA',
    three_digit_code: 'VAT',
    numeric: 336,
  },
  {
    name: 'Honduras',
    two_digit_code: 'HN',
    three_digit_code: 'HND',
    numeric: 340,
  },
  {
    name: 'Hong Kong',
    two_digit_code: 'HK',
    three_digit_code: 'HKG',
    numeric: 344,
  },
  {
    name: 'Hungary',
    two_digit_code: 'HU',
    three_digit_code: 'HUN',
    numeric: 348,
  },
  {
    name: 'Iceland',
    two_digit_code: 'IS',
    three_digit_code: 'ISL',
    numeric: 352,
  },
  {
    name: 'India',
    two_digit_code: 'IN',
    three_digit_code: 'IND',
    numeric: 356,
  },
  {
    name: 'Indonesia',
    two_digit_code: 'ID',
    three_digit_code: 'IDN',
    numeric: 360,
  },
  {
    name: 'Iran (Islamic Republic of)',
    two_digit_code: 'IR',
    three_digit_code: 'IRN',
    numeric: 364,
  },
  {
    name: 'Iraq',
    two_digit_code: 'IQ',
    three_digit_code: 'IRQ',
    numeric: 368,
  },
  {
    name: 'Ireland',
    two_digit_code: 'IE',
    three_digit_code: 'IRL',
    numeric: 372,
  },
  {
    name: 'Isle of Man',
    two_digit_code: 'IM',
    three_digit_code: 'IMN',
    numeric: 833,
  },
  {
    name: 'Israel',
    two_digit_code: 'IL',
    three_digit_code: 'ISR',
    numeric: 376,
  },
  {
    name: 'Italy',
    two_digit_code: 'IT',
    three_digit_code: 'ITA',
    numeric: 380,
  },
  {
    name: 'Jamaica',
    two_digit_code: 'JM',
    three_digit_code: 'JAM',
    numeric: 388,
  },
  {
    name: 'Japan',
    two_digit_code: 'JP',
    three_digit_code: 'JPN',
    numeric: 392,
  },
  {
    name: 'Jersey',
    two_digit_code: 'JE',
    three_digit_code: 'JEY',
    numeric: 832,
  },
  {
    name: 'Jordan',
    two_digit_code: 'JO',
    three_digit_code: 'JOR',
    numeric: 400,
  },
  {
    name: 'Kazakhstan',
    two_digit_code: 'KZ',
    three_digit_code: 'KAZ',
    numeric: 398,
  },
  {
    name: 'Kenya',
    two_digit_code: 'KE',
    three_digit_code: 'KEN',
    numeric: 404,
  },
  {
    name: 'Kiribati',
    two_digit_code: 'KI',
    three_digit_code: 'KIR',
    numeric: 296,
  },
  {
    name: "Korea (the Democratic People's Republic of)",
    two_digit_code: 'KP',
    three_digit_code: 'PRK',
    numeric: 408,
  },
  {
    name: 'Korea (the Republic of)',
    two_digit_code: 'KR',
    three_digit_code: 'KOR',
    numeric: 410,
  },
  {
    name: 'Kuwait',
    two_digit_code: 'KW',
    three_digit_code: 'KWT',
    numeric: 414,
  },
  {
    name: 'Kyrgyzstan',
    two_digit_code: 'KG',
    three_digit_code: 'KGZ',
    numeric: 417,
  },
  {
    name: "Lao People's Democratic Republic (the)",
    two_digit_code: 'LA',
    three_digit_code: 'LAO',
    numeric: 418,
  },
  {
    name: 'Latvia',
    two_digit_code: 'LV',
    three_digit_code: 'LVA',
    numeric: 428,
  },
  {
    name: 'Lebanon',
    two_digit_code: 'LB',
    three_digit_code: 'LBN',
    numeric: 422,
  },
  {
    name: 'Lesotho',
    two_digit_code: 'LS',
    three_digit_code: 'LSO',
    numeric: 426,
  },
  {
    name: 'Liberia',
    two_digit_code: 'LR',
    three_digit_code: 'LBR',
    numeric: 430,
  },
  {
    name: 'Libya',
    two_digit_code: 'LY',
    three_digit_code: 'LBY',
    numeric: 434,
  },
  {
    name: 'Liechtenstein',
    two_digit_code: 'LI',
    three_digit_code: 'LIE',
    numeric: 438,
  },
  {
    name: 'Lithuania',
    two_digit_code: 'LT',
    three_digit_code: 'LTU',
    numeric: 440,
  },
  {
    name: 'Luxembourg',
    two_digit_code: 'LU',
    three_digit_code: 'LUX',
    numeric: 442,
  },
  {
    name: 'Macao',
    two_digit_code: 'MO',
    three_digit_code: 'MAC',
    numeric: 446,
  },
  {
    name: 'Republic of North Macedonia',
    two_digit_code: 'MK',
    three_digit_code: 'MKD',
    numeric: 807,
  },
  {
    name: 'Madagascar',
    two_digit_code: 'MG',
    three_digit_code: 'MDG',
    numeric: 450,
  },
  {
    name: 'Malawi',
    two_digit_code: 'MW',
    three_digit_code: 'MWI',
    numeric: 454,
  },
  {
    name: 'Malaysia',
    two_digit_code: 'MY',
    three_digit_code: 'MYS',
    numeric: 458,
  },
  {
    name: 'Maldives',
    two_digit_code: 'MV',
    three_digit_code: 'MDV',
    numeric: 462,
  },
  {
    name: 'Mali',
    two_digit_code: 'ML',
    three_digit_code: 'MLI',
    numeric: 466,
  },
  {
    name: 'Malta',
    two_digit_code: 'MT',
    three_digit_code: 'MLT',
    numeric: 470,
  },
  {
    name: 'Marshall Islands (the)',
    two_digit_code: 'MH',
    three_digit_code: 'MHL',
    numeric: 584,
  },
  {
    name: 'Martinique',
    two_digit_code: 'MQ',
    three_digit_code: 'MTQ',
    numeric: 474,
  },
  {
    name: 'Mauritania',
    two_digit_code: 'MR',
    three_digit_code: 'MRT',
    numeric: 478,
  },
  {
    name: 'Mauritius',
    two_digit_code: 'MU',
    three_digit_code: 'MUS',
    numeric: 480,
  },
  {
    name: 'Mayotte',
    two_digit_code: 'YT',
    three_digit_code: 'MYT',
    numeric: 175,
  },
  {
    name: 'Mexico',
    two_digit_code: 'MX',
    three_digit_code: 'MEX',
    numeric: 484,
  },
  {
    name: 'Micronesia (Federated States of)',
    two_digit_code: 'FM',
    three_digit_code: 'FSM',
    numeric: 583,
  },
  {
    name: 'Moldova (the Republic of)',
    two_digit_code: 'MD',
    three_digit_code: 'MDA',
    numeric: 498,
  },
  {
    name: 'Monaco',
    two_digit_code: 'MC',
    three_digit_code: 'MCO',
    numeric: 492,
  },
  {
    name: 'Mongolia',
    two_digit_code: 'MN',
    three_digit_code: 'MNG',
    numeric: 496,
  },
  {
    name: 'Montenegro',
    two_digit_code: 'ME',
    three_digit_code: 'MNE',
    numeric: 499,
  },
  {
    name: 'Montserrat',
    two_digit_code: 'MS',
    three_digit_code: 'MSR',
    numeric: 500,
  },
  {
    name: 'Morocco',
    two_digit_code: 'MA',
    three_digit_code: 'MAR',
    numeric: 504,
  },
  {
    name: 'Mozambique',
    two_digit_code: 'MZ',
    three_digit_code: 'MOZ',
    numeric: 508,
  },
  {
    name: 'Myanmar',
    two_digit_code: 'MM',
    three_digit_code: 'MMR',
    numeric: 104,
  },
  {
    name: 'Namibia',
    two_digit_code: 'NA',
    three_digit_code: 'NAM',
    numeric: 516,
  },
  {
    name: 'Nauru',
    two_digit_code: 'NR',
    three_digit_code: 'NRU',
    numeric: 520,
  },
  {
    name: 'Nepal',
    two_digit_code: 'NP',
    three_digit_code: 'NPL',
    numeric: 524,
  },
  {
    name: 'Netherlands (the)',
    two_digit_code: 'NL',
    three_digit_code: 'NLD',
    numeric: 528,
  },
  {
    name: 'New Caledonia',
    two_digit_code: 'NC',
    three_digit_code: 'NCL',
    numeric: 540,
  },
  {
    name: 'New Zealand',
    two_digit_code: 'NZ',
    three_digit_code: 'NZL',
    numeric: 554,
  },
  {
    name: 'Nicaragua',
    two_digit_code: 'NI',
    three_digit_code: 'NIC',
    numeric: 558,
  },
  {
    name: 'Niger (the)',
    two_digit_code: 'NE',
    three_digit_code: 'NER',
    numeric: 562,
  },
  {
    name: 'Nigeria',
    two_digit_code: 'NG',
    three_digit_code: 'NGA',
    numeric: 566,
  },
  {
    name: 'Niue',
    two_digit_code: 'NU',
    three_digit_code: 'NIU',
    numeric: 570,
  },
  {
    name: 'Norfolk Island',
    two_digit_code: 'NF',
    three_digit_code: 'NFK',
    numeric: 574,
  },
  {
    name: 'Northern Mariana Islands (the)',
    two_digit_code: 'MP',
    three_digit_code: 'MNP',
    numeric: 580,
  },
  {
    name: 'Norway',
    two_digit_code: 'NO',
    three_digit_code: 'NOR',
    numeric: 578,
  },
  {
    name: 'Oman',
    two_digit_code: 'OM',
    three_digit_code: 'OMN',
    numeric: 512,
  },
  {
    name: 'Pakistan',
    two_digit_code: 'PK',
    three_digit_code: 'PAK',
    numeric: 586,
  },
  {
    name: 'Palau',
    two_digit_code: 'PW',
    three_digit_code: 'PLW',
    numeric: 585,
  },
  {
    name: 'Palestine, State of',
    two_digit_code: 'PS',
    three_digit_code: 'PSE',
    numeric: 275,
  },
  {
    name: 'Panama',
    two_digit_code: 'PA',
    three_digit_code: 'PAN',
    numeric: 591,
  },
  {
    name: 'Papua New Guinea',
    two_digit_code: 'PG',
    three_digit_code: 'PNG',
    numeric: 598,
  },
  {
    name: 'Paraguay',
    two_digit_code: 'PY',
    three_digit_code: 'PRY',
    numeric: 600,
  },
  {
    name: 'Peru',
    two_digit_code: 'PE',
    three_digit_code: 'PER',
    numeric: 604,
  },
  {
    name: 'Philippines (the)',
    two_digit_code: 'PH',
    three_digit_code: 'PHL',
    numeric: 608,
  },
  {
    name: 'Pitcairn',
    two_digit_code: 'PN',
    three_digit_code: 'PCN',
    numeric: 612,
  },
  {
    name: 'Poland',
    two_digit_code: 'PL',
    three_digit_code: 'POL',
    numeric: 616,
  },
  {
    name: 'Portugal',
    two_digit_code: 'PT',
    three_digit_code: 'PRT',
    numeric: 620,
  },
  {
    name: 'Puerto Rico',
    two_digit_code: 'PR',
    three_digit_code: 'PRI',
    numeric: 630,
  },
  {
    name: 'Qatar',
    two_digit_code: 'QA',
    three_digit_code: 'QAT',
    numeric: 634,
  },
  {
    name: 'Réunion',
    two_digit_code: 'RE',
    three_digit_code: 'REU',
    numeric: 638,
  },
  {
    name: 'Romania',
    two_digit_code: 'RO',
    three_digit_code: 'ROU',
    numeric: 642,
  },
  {
    name: 'Russian Federation (the)',
    two_digit_code: 'RU',
    three_digit_code: 'RUS',
    numeric: 643,
  },
  {
    name: 'Rwanda',
    two_digit_code: 'RW',
    three_digit_code: 'RWA',
    numeric: 646,
  },
  {
    name: 'Saint Barthélemy',
    two_digit_code: 'BL',
    three_digit_code: 'BLM',
    numeric: 652,
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    two_digit_code: 'SH',
    three_digit_code: 'SHN',
    numeric: 654,
  },
  {
    name: 'Saint Kitts and Nevis',
    two_digit_code: 'KN',
    three_digit_code: 'KNA',
    numeric: 659,
  },
  {
    name: 'Saint Lucia',
    two_digit_code: 'LC',
    three_digit_code: 'LCA',
    numeric: 662,
  },
  {
    name: 'Saint Martin (French part)',
    two_digit_code: 'MF',
    three_digit_code: 'MAF',
    numeric: 663,
  },
  {
    name: 'Saint Pierre and Miquelon',
    two_digit_code: 'PM',
    three_digit_code: 'SPM',
    numeric: 666,
  },
  {
    name: 'Saint Vincent and the Grenadines',
    two_digit_code: 'VC',
    three_digit_code: 'VCT',
    numeric: 670,
  },
  {
    name: 'Samoa',
    two_digit_code: 'WS',
    three_digit_code: 'WSM',
    numeric: 882,
  },
  {
    name: 'San Marino',
    two_digit_code: 'SM',
    three_digit_code: 'SMR',
    numeric: 674,
  },
  {
    name: 'Sao Tome and Principe',
    two_digit_code: 'ST',
    three_digit_code: 'STP',
    numeric: 678,
  },
  {
    name: 'Saudi Arabia',
    two_digit_code: 'SA',
    three_digit_code: 'SAU',
    numeric: 682,
  },
  {
    name: 'Senegal',
    two_digit_code: 'SN',
    three_digit_code: 'SEN',
    numeric: 686,
  },
  {
    name: 'Serbia',
    two_digit_code: 'RS',
    three_digit_code: 'SRB',
    numeric: 688,
  },
  {
    name: 'Seychelles',
    two_digit_code: 'SC',
    three_digit_code: 'SYC',
    numeric: 690,
  },
  {
    name: 'Sierra Leone',
    two_digit_code: 'SL',
    three_digit_code: 'SLE',
    numeric: 694,
  },
  {
    name: 'Singapore',
    two_digit_code: 'SG',
    three_digit_code: 'SGP',
    numeric: 702,
  },
  {
    name: 'Sint Maarten (Dutch part)',
    two_digit_code: 'SX',
    three_digit_code: 'SXM',
    numeric: 534,
  },
  {
    name: 'Slovakia',
    two_digit_code: 'SK',
    three_digit_code: 'SVK',
    numeric: 703,
  },
  {
    name: 'Slovenia',
    two_digit_code: 'SI',
    three_digit_code: 'SVN',
    numeric: 705,
  },
  {
    name: 'Solomon Islands',
    two_digit_code: 'SB',
    three_digit_code: 'SLB',
    numeric: 90,
  },
  {
    name: 'Somalia',
    two_digit_code: 'SO',
    three_digit_code: 'SOM',
    numeric: 706,
  },
  {
    name: 'South Africa',
    two_digit_code: 'ZA',
    three_digit_code: 'ZAF',
    numeric: 710,
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    two_digit_code: 'GS',
    three_digit_code: 'SGS',
    numeric: 239,
  },
  {
    name: 'South Sudan',
    two_digit_code: 'SS',
    three_digit_code: 'SSD',
    numeric: 728,
  },
  {
    name: 'Spain',
    two_digit_code: 'ES',
    three_digit_code: 'ESP',
    numeric: 724,
  },
  {
    name: 'Sri Lanka',
    two_digit_code: 'LK',
    three_digit_code: 'LKA',
    numeric: 144,
  },
  {
    name: 'Sudan (the)',
    two_digit_code: 'SD',
    three_digit_code: 'SDN',
    numeric: 729,
  },
  {
    name: 'Suriname',
    two_digit_code: 'SR',
    three_digit_code: 'SUR',
    numeric: 740,
  },
  {
    name: 'Svalbard and Jan Mayen',
    two_digit_code: 'SJ',
    three_digit_code: 'SJM',
    numeric: 744,
  },
  {
    name: 'Sweden',
    two_digit_code: 'SE',
    three_digit_code: 'SWE',
    numeric: 752,
  },
  {
    name: 'Switzerland',
    two_digit_code: 'CH',
    three_digit_code: 'CHE',
    numeric: 756,
  },
  {
    name: 'Syrian Arab Republic',
    two_digit_code: 'SY',
    three_digit_code: 'SYR',
    numeric: 760,
  },
  {
    name: 'Taiwan (Province of China)',
    two_digit_code: 'TW',
    three_digit_code: 'TWN',
    numeric: 158,
  },
  {
    name: 'Tajikistan',
    two_digit_code: 'TJ',
    three_digit_code: 'TJK',
    numeric: 762,
  },
  {
    name: 'Tanzania, United Republic of',
    two_digit_code: 'TZ',
    three_digit_code: 'TZA',
    numeric: 834,
  },
  {
    name: 'Thailand',
    two_digit_code: 'TH',
    three_digit_code: 'THA',
    numeric: 764,
  },
  {
    name: 'Timor-Leste',
    two_digit_code: 'TL',
    three_digit_code: 'TLS',
    numeric: 626,
  },
  {
    name: 'Togo',
    two_digit_code: 'TG',
    three_digit_code: 'TGO',
    numeric: 768,
  },
  {
    name: 'Tokelau',
    two_digit_code: 'TK',
    three_digit_code: 'TKL',
    numeric: 772,
  },
  {
    name: 'Tonga',
    two_digit_code: 'TO',
    three_digit_code: 'TON',
    numeric: 776,
  },
  {
    name: 'Trinidad and Tobago',
    two_digit_code: 'TT',
    three_digit_code: 'TTO',
    numeric: 780,
  },
  {
    name: 'Tunisia',
    two_digit_code: 'TN',
    three_digit_code: 'TUN',
    numeric: 788,
  },
  {
    name: 'Turkey',
    two_digit_code: 'TR',
    three_digit_code: 'TUR',
    numeric: 792,
  },
  {
    name: 'Turkmenistan',
    two_digit_code: 'TM',
    three_digit_code: 'TKM',
    numeric: 795,
  },
  {
    name: 'Turks and Caicos Islands (the)',
    two_digit_code: 'TC',
    three_digit_code: 'TCA',
    numeric: 796,
  },
  {
    name: 'Tuvalu',
    two_digit_code: 'TV',
    three_digit_code: 'TUV',
    numeric: 798,
  },
  {
    name: 'Uganda',
    two_digit_code: 'UG',
    three_digit_code: 'UGA',
    numeric: 800,
  },
  {
    name: 'Ukraine',
    two_digit_code: 'UA',
    three_digit_code: 'UKR',
    numeric: 804,
  },
  {
    name: 'United Arab Emirates (the)',
    two_digit_code: 'AE',
    three_digit_code: 'ARE',
    numeric: 784,
  },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland (the)',
    two_digit_code: 'GB',
    three_digit_code: 'GBR',
    numeric: 826,
  },
  {
    name: 'United States Minor Outlying Islands (the)',
    two_digit_code: 'UM',
    three_digit_code: 'UMI',
    numeric: 581,
  },
  { ...USA },
  {
    name: 'Uruguay',
    two_digit_code: 'UY',
    three_digit_code: 'URY',
    numeric: 858,
  },
  {
    name: 'Uzbekistan',
    two_digit_code: 'UZ',
    three_digit_code: 'UZB',
    numeric: 860,
  },
  {
    name: 'Vanuatu',
    two_digit_code: 'VU',
    three_digit_code: 'VUT',
    numeric: 548,
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    two_digit_code: 'VE',
    three_digit_code: 'VEN',
    numeric: 862,
  },
  {
    name: 'Viet Nam',
    two_digit_code: 'VN',
    three_digit_code: 'VNM',
    numeric: 704,
  },
  {
    name: 'Virgin Islands (British)',
    two_digit_code: 'VG',
    three_digit_code: 'VGB',
    numeric: 92,
  },
  {
    name: 'Virgin Islands (U.S.)',
    two_digit_code: 'VI',
    three_digit_code: 'VIR',
    numeric: 850,
  },
  {
    name: 'Wallis and Futuna',
    two_digit_code: 'WF',
    three_digit_code: 'WLF',
    numeric: 876,
  },
  {
    name: 'Western Sahara',
    two_digit_code: 'EH',
    three_digit_code: 'ESH',
    numeric: 732,
  },
  {
    name: 'Yemen',
    two_digit_code: 'YE',
    three_digit_code: 'YEM',
    numeric: 887,
  },
  {
    name: 'Zambia',
    two_digit_code: 'ZM',
    three_digit_code: 'ZMB',
    numeric: 894,
  },
  {
    name: 'Zimbabwe',
    two_digit_code: 'ZW',
    three_digit_code: 'ZWE',
    numeric: 716,
  },
];
