import { createSelector } from 'reselect';

import { FLOW_ROUTES, routes } from '../../constants/routes';
import { DEFAULT_LOGO_URL } from '../../constants/ui';
import { ICustomizableViewItem } from '../../types/inventory';
import { ICampaign, ICampaignItem } from '../../types/pyg';
import { getPickedItemsByDefault, getUniqueItemsWithCounts, isMskuItem } from '../../util/helpers';
import { selectCustomizableItems, selectPickedCampaignId, selectPickedItems } from './form';
import {
  selectCampaigns,
  selectInitialAddress,
  selectIsPYGFlow,
  selectItems,
  selectOrgData,
  selectSenderData,
  selectWhiteLabeling,
} from './init';

export const selectPickedCampaign = createSelector(selectPickedCampaignId, selectCampaigns, (campaignId, campaigns) =>
  campaignId && campaigns?.length ? campaigns.find(({ id }: ICampaign) => id === campaignId) : undefined,
);

export const selectHasMSKUItems = createSelector(
  selectItems,
  selectPickedCampaign,
  (items, campaign) => items?.some(isMskuItem) || campaign?.items?.some(isMskuItem),
);

export const selectOrgLogo = createSelector(
  selectOrgData,
  selectWhiteLabeling,
  selectPickedCampaign,
  (orgInfo, whiteLabeling, pickedCampaign) =>
    pickedCampaign?.ds_logo_url || whiteLabeling?.ds_logo_url || orgInfo?.logo_url || DEFAULT_LOGO_URL,
);

export const selectSenderWLData = createSelector(
  selectSenderData,
  selectWhiteLabeling,
  selectPickedCampaign,
  (sender, whiteLabeling, pickedCampaign) =>
    sender
      ? {
          ...sender,
          sender_company_name:
            pickedCampaign?.ds_company_name || whiteLabeling?.ds_company_name || sender.sender_company_name,
        }
      : null,
);

export const selectFlowSteps = (path: string) =>
  createSelector(
    selectIsPYGFlow,
    selectHasMSKUItems,
    selectPickedItems,
    selectPickedCampaignId,
    selectHasCustomizableItems,
    selectHasRequiredNonCustomizedItems,
    (isPYGFlow, hasMSKU, pickedItems, pickedCampaignId, hasCustomizableItems, hasRequiredNonCustomizedItems) =>
      FLOW_ROUTES.includes(path)
        ? [
            ...(isPYGFlow
              ? [
                  {
                    id: routes.pyg,
                    route: routes.pyg,
                    label: 'Pick your gift',
                  },
                ]
              : []),
            ...(hasMSKU
              ? [
                  {
                    id: routes.pickMsku,
                    route: routes.pickMsku,
                    label: 'Pick your item',
                  },
                ]
              : []),
            ...(hasCustomizableItems
              ? [
                  {
                    id: routes.customizeItems,
                    route: routes.customizeItems,
                    label: 'Customize items',
                  },
                ]
              : []),
            {
              id: routes.address,
              route: routes.address,
              label: 'Confirm delivery address',
              isDisabled:
                (path === routes.pyg && !pickedCampaignId) ||
                (path === routes.pickMsku && !Object.values(pickedItems).length) ||
                (path === routes.customizeItems && hasRequiredNonCustomizedItems),
            },
          ]
        : [],
  );

export const selectIsFirstStep = (pathname: string) =>
  createSelector(selectFlowSteps(pathname), ([firstStep]) => {
    return firstStep?.route === pathname;
  });

export const selectPrioritizedWL = createSelector(
  selectPickedCampaign,
  selectWhiteLabeling,
  (pickedCampaign, whiteLabeling) => {
    const {
      ds_shipping_page_title: pickedCampaignSPTitle,
      ds_shipping_page_body: pickedCampaignSPBody,
      ds_msku_page_title: pickedCampaignMSKUTitle,
      ds_msku_page_body: pickedCampaignMSKUBody,
      ds_customize_items_page_title: pickedCampaignCustomizationTitle,
      ds_customize_items_page_body: pickedCampaignCustomizationBody,
    } = pickedCampaign || {};
    const {
      shipping_page_title: orgShippingTitle,
      shipping_page_body: orgShippingBody,
      pick_your_gift_title,
      pick_your_gift_body,
      msku_page_title: orgMKSUTitle,
      msku_page_body: orgMKSUBody,
      customize_items_page_title: orgCustomizeItemsTitle,
      customize_items_page_body: orgCustomizeItemsBody,
      ds_shipping_page_title: campaignShippingTitle,
      ds_shipping_page_body: campaignShippingBody,
      ds_msku_page_title: campaignMKSUTitle,
      ds_msku_page_body: campaignMKSUBody,
      ds_customize_items_page_title: campaignCustomizeItemsTitle,
      ds_customize_items_page_body: campaignCustomizeItemsBody,
    } = whiteLabeling || {};

    return {
      shipping_page_title: pickedCampaignSPTitle || campaignShippingTitle || orgShippingTitle,
      shipping_page_body: pickedCampaignSPBody || campaignShippingBody || orgShippingBody,
      pick_your_gift_title,
      pick_your_gift_body,
      msku_page_title: pickedCampaignMSKUTitle || campaignMKSUTitle || orgMKSUTitle,
      msku_page_body: pickedCampaignMSKUBody || campaignMKSUBody || orgMKSUBody,
      customize_items_page_title:
        pickedCampaignCustomizationTitle || campaignCustomizeItemsTitle || orgCustomizeItemsTitle,
      customize_items_page_body: pickedCampaignCustomizationBody || campaignCustomizeItemsBody || orgCustomizeItemsBody,
    };
  },
);

export const selectGreetingTitle = (pathname: string) =>
  createSelector(
    selectSenderData,
    selectInitialAddress,
    selectPrioritizedWL,
    selectIsFirstStep(pathname),
    (senderData, receiverData, whiteLabeling, isFirstStep) => {
      let title = '';

      const { receiver_first_name } = receiverData || {};
      const { shipping_page_title, pick_your_gift_title, msku_page_title, customize_items_page_title } =
        whiteLabeling || {};

      switch (pathname) {
        case routes.pyg: {
          title =
            pick_your_gift_title ||
            (isFirstStep
              ? `Hey${receiver_first_name ? ` ${receiver_first_name}, ` : ', '}pick your gift!`
              : `${receiver_first_name ? `${receiver_first_name}, pick` : 'Pick'} your gift!`);
          break;
        }
        case routes.pickMsku: {
          title =
            msku_page_title ||
            (isFirstStep
              ? `Hey${receiver_first_name ? ` ${receiver_first_name}` : ''}, select from the following options`
              : `${receiver_first_name ? `${receiver_first_name}, select` : 'Select'} from the following options`);
          break;
        }
        case routes.customizeItems: {
          title =
            customize_items_page_title ||
            (isFirstStep
              ? `Hey${receiver_first_name ? ` ${receiver_first_name}` : ''}, customize your items`
              : `${receiver_first_name ? `${receiver_first_name}, customize` : 'Customize'} your items`);
          break;
        }
        case routes.address: {
          title =
            shipping_page_title ||
            (isFirstStep
              ? `Hey${receiver_first_name ? ` ${receiver_first_name}` : ''}, the gift is almost ready!`
              : `${receiver_first_name ? `${receiver_first_name}, the` : 'The'} gift is almost ready!`);
          break;
        }
        default: {
          break;
        }
      }

      return title;
    },
  );

export const selectMappedPickedItems = createSelector(selectPickedItems, selectItems, (pickedItems, inventoryItems) => {
  if (pickedItems && inventoryItems?.length) {
    const pickedByDefaultItems = getPickedItemsByDefault(inventoryItems);
    const pickedItemsArray = getUniqueItemsWithCounts(Object.values({ ...pickedItems, ...pickedByDefaultItems }));

    const skuOptionsArray = inventoryItems
      ?.map((item) => {
        if (item?.sku_options) {
          return item.sku_options;
        }

        return [item];
      })
      .flat();

    const resultItems: ICampaignItem[] = [];

    pickedItemsArray.forEach((item) => {
      if (item) {
        const { uid, quantity } = item;
        const itemToShow = skuOptionsArray.find((skuOption) => skuOption?.id === uid);
        if (itemToShow) {
          resultItems.push({
            ...itemToShow,
            quantity,
          });
        }
      }
    });

    return resultItems;
  }

  return inventoryItems || [];
});

export const selectCustomizableViewItems = createSelector(
  selectMappedPickedItems,
  selectCustomizableItems,
  (inventoryItems, customItems) =>
    Object.values(customItems || {}).map((customizableItem) => {
      const { internalId, item_id, item_customization_text } = customizableItem || {};
      const { name, image_url, customization } = inventoryItems?.find((i) => i.id === item_id) || {};

      const result: ICustomizableViewItem = {
        internalId,
        item_id,
        name,
        image_url,
        item_customization_text,
        ...customization,
      };

      return result;
    }),
);

export const selectItemsWithRequiredCustomization = createSelector(selectCustomizableViewItems, (customizableItems) => {
  return customizableItems?.filter((item) => {
    return item?.item_customization_required;
  });
});

export const selectHasRequiredNonCustomizedItems = createSelector(
  selectItemsWithRequiredCustomization,
  (requiredCustomizationItems) => {
    return !!(
      requiredCustomizationItems.length &&
      requiredCustomizationItems.some((item) => {
        return !item?.item_customization_text;
      })
    );
  },
);

export const selectHasCustomizableItems = createSelector(
  selectCustomizableViewItems,
  (items) => items && items.length > 0,
);

export const selectCampaignWLData = createSelector(
  selectPickedCampaignId,
  selectCampaigns,
  (pickedCampaignId, campaigns) => {
    if (!pickedCampaignId || !campaigns?.length) {
      return null;
    }

    const pickedCampaign = campaigns.find((campaign) => campaign.id === pickedCampaignId);

    const { ds_button_color, ds_header_color, ds_shipping_page_body, ds_shipping_page_title } = pickedCampaign || {};

    return {
      ds_button_color,
      ds_header_color,
      ds_shipping_page_body,
      ds_shipping_page_title,
    };
  },
);
