import * as React from 'react';
import { Card } from 'react-bootstrap';

import { CUSTOMIZABLE_ITEM_DEFAULT_LABEL_TEXT } from '../../constants/ui';
import Input from '../Input/Input';
import LightboxBackgroundImage from '../LightboxBackgroundImage/LightboxBackgroundImage';

import styles from './CustomizableItem.module.scss';

export interface ICustomizableItemProps {
  itemName: string;
  label?: string;
  value?: string;
  name?: string;
  error?: string;
  imageUrl: string;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  isCustomizationRequired?: boolean;
}

const CustomizableItem: React.FC<ICustomizableItemProps> = ({
  itemName,
  label = CUSTOMIZABLE_ITEM_DEFAULT_LABEL_TEXT,
  value,
  imageUrl,
  onChange,
  name,
  isCustomizationRequired,
  error,
}) => {
  const handleInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e);
      }
    },
    [onChange],
  );

  return (
    <Card className={styles.container}>
      <Card.Body className={styles.itemContentContainer}>
        <div className={styles.info}>
          <LightboxBackgroundImage className={styles.itemImage} url={imageUrl} stopPropagation preventDefault />
          <p className={styles.name}>{itemName}</p>
        </div>
        <Input
          type="text"
          label={label}
          value={value || ''}
          name={name}
          onChange={handleInputChange}
          className={styles.input}
          error={error}
        />
      </Card.Body>
      {isCustomizationRequired && <span className={styles.requiredLabel}>* required</span>}
    </Card>
  );
};

export default CustomizableItem;
