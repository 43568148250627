import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { compose, Store } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import { IAppState } from '../types/store';
import reducer from './reducers';
import rootSaga from './sagas';
import transforms from './transforms';

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['form', 'init', 'router'],
  transforms,
};
export const history = createBrowserHistory();

const rootReducer = reducer(history);

const persistedReducer = persistReducer<IAppState>(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware, routerMiddleware(history)];

const store: Store = configureStore({
  reducer: persistedReducer,
  middleware,
  devTools: true,
});

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export { store, persistor };
