import cn from 'classnames';
import { FormikHelpers, useFormik } from 'formik';
import * as React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { PASSWORD } from '../../../constants/shipping';
import { setPassword } from '../../../store/actions/init';
import { IAuthInfo } from '../../../types/shipping';
import Input from '../../Input/Input';
import SubmitButton from '../../SubmitButton/SubmitButton';

import { ReactComponent as LockIcon } from '../../../assets/icon-lock.svg';

import styles from './PasswordForm.module.scss';

interface IProps {
  initialValues: IAuthInfo;
  handleSubmit: (formikHelpers: FormikHelpers<IAuthInfo>) => Promise<void>;
}

const PasswordForm = ({ initialValues, handleSubmit }: IProps) => {
  const dispatch = useDispatch();

  const form = useFormik<IAuthInfo>({
    initialValues,
    onSubmit: (values: IAuthInfo, actions: FormikHelpers<IAuthInfo>) => {
      if (values) {
        handleSubmit(actions);
      }
    },
  });

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      form.handleChange(e);
      dispatch(setPassword(e.target.value));
    },
    [form.handleChange],
  );

  return (
    <Form className={cn(styles.passwordForm)}>
      <Row className={styles.row}>
        <span className={styles.label}>Enter password</span>
        <Col className={styles.inputWrapper}>
          <Input
            isRequired
            type="text"
            name={PASSWORD}
            onChange={handleChange}
            value={form.values?.[PASSWORD] || ''}
            className={styles.input}
          />
          <LockIcon />
        </Col>
      </Row>
      <Row className={styles.row}>
        <SubmitButton
          className={styles.submit}
          onSubmit={form.handleSubmit}
          disabled={form.isSubmitting || !form.values?.[PASSWORD]}
        >
          Continue
        </SubmitButton>
      </Row>
    </Form>
  );
};

export default PasswordForm;
