import cn from 'classnames';
import * as React from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import styles from './Slider.module.scss';
import './SliderCustom.css';

export interface ISliderProps {
  children: React.ReactNode[] | React.ReactNode;
  className?: string;
}

const Slider: React.FC<ISliderProps> = ({ children, className }) => {
  return (
    <Swiper
      loop
      pagination={{
        clickable: true,
        bulletClass: cn('swiper-pagination-bullet', styles.bullet),
        bulletActiveClass: cn('swiper-pagination-bullet-active', styles.active),
      }}
      navigation
      modules={[Pagination, Navigation]}
      className={cn(styles.slideWrapper, className)}
    >
      {children}
    </Swiper>
  );
};

export default Slider;
