import {
  ActionsEnum,
  IAction,
  IError,
  IInitState,
  IInitSuccessPayload,
  ISetWhiteLabelingPayload,
  ISubmitPYGPayload,
} from '../../types/store';
import { formatSkuOptions } from '../../util/helpers';

export const initialState: IInitState = {
  isLoading: false,
  error: null,
  org: null,
  whiteLabeling: null,
  sender: null,
  address: null,
  campaigns: null,
  items: null,
  isDigital: false,
  isFormSubmitted: false,
  password: null,
  send2FATo: null,
  code: null,
};

const reducer = (state: IInitState = initialState, action: IAction): IInitState => {
  switch (action.type) {
    case ActionsEnum.GetFormDataRequest:
    case ActionsEnum.UpdateRequest:
      return { ...state, isLoading: true };
    case ActionsEnum.GetFormDataFailure:
      return { ...state, isLoading: false, error: action.payload as IError };
    case ActionsEnum.GetFormDataSuccess: {
      const {
        inventory_items,
        ds_customized_texts: whiteLabeling,
        pick_campaigns: campaigns,
        org_info: org,
        sender_info: sender,
        is_digital,
        receiver_info: address,
      } = action.payload as IInitSuccessPayload;

      const items = formatSkuOptions(inventory_items);

      return {
        ...state,
        isLoading: false,
        items,
        campaigns,
        address,
        org,
        sender,
        isDigital: !!is_digital,
        whiteLabeling,
      };
    }
    case ActionsEnum.UpdateSuccess:
    case ActionsEnum.UpdateFailure: {
      return { ...state, isLoading: false };
    }
    case ActionsEnum.SubmitPYG: {
      const { campaignId } = action.payload as ISubmitPYGPayload;
      const selectedCampaign = state.campaigns?.find(({ id }) => campaignId === id);
      const items = selectedCampaign ? formatSkuOptions(selectedCampaign.items) : undefined;

      return {
        ...state,
        ...(items ? { items } : {}),
        isDigital: !!selectedCampaign?.is_digital,
      };
    }
    case ActionsEnum.SetIsFormSubmitted: {
      return { ...state, isFormSubmitted: !!action.payload };
    }
    case ActionsEnum.SetPassword: {
      return { ...state, password: action.payload as string };
    }
    case ActionsEnum.Set2FATo: {
      return { ...state, send2FATo: action.payload as string };
    }
    case ActionsEnum.Set2FACode: {
      return { ...state, code: action.payload as string };
    }
    case ActionsEnum.SetWhiteLabeling: {
      const {
        ds_customized_texts: whiteLabeling,
        org_info: org,
        sender_info: sender,
      } = action.payload as ISetWhiteLabelingPayload;
      return {
        ...state,
        org,
        sender,
        whiteLabeling,
      };
    }
    default:
      return state;
  }
};

export default reducer;
