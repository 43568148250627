import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers, Reducer } from 'redux';

import { IAppState } from '../../types/store';

import form from './form';
import init from './init';
import keys from './keys';
import shell from './shell';

export default (history: History): Reducer<IAppState> =>
  combineReducers({
    keys,
    init,
    form,
    router: connectRouter(history),
    shell,
  });
