import { IDigitalReceiverInfo, IFixedAddress, IPhysicalReceiverInfo, TInitAddress } from '../types/api';
import { emptyDigitalReceiver, emptyPhysicalReceiver } from './address';

export const mapPhysicalReceiverInfo = (initAddress: TInitAddress | null): IPhysicalReceiverInfo => {
  if (!initAddress || initAddress.fixed_address) {
    return emptyPhysicalReceiver;
  }

  const { fixed_address, ...address } = initAddress;

  return {
    ...address,
  };
};

export const mapDigitalReceiverInfo = (initAddress: TInitAddress | null): IDigitalReceiverInfo => {
  if (!initAddress) {
    return emptyDigitalReceiver;
  }

  const { receiver_email, receiver_phone, receiver_first_name, receiver_last_name } = initAddress;
  return {
    receiver_email,
    receiver_phone,
    receiver_first_name,
    receiver_last_name,
  };
};

export const isValidFixedAddress = (fixedAddress?: IFixedAddress): boolean => {
  if (!fixedAddress) {
    return false;
  }

  const { address2, state, ...address } = fixedAddress;

  return Object.values(address).every(Boolean);
};
