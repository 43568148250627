import { useCallback } from 'react';
import { PAGE_NAME } from '../constants/analytics';
import { IAnalyticsEvent } from '../types/analytics';
import {
  apiException as formatApiException,
  event as formatEvent,
  exception as formatException,
  page as formatPage,
} from '../util/analytics';

const ga = window.ga;

export const page = (pageName: string = PAGE_NAME) => ga('send', formatPage(pageName));

export const event = (e: IAnalyticsEvent) => ga('send', formatEvent(e));

export const exception = (error: any) => ga('send', formatException(error));

export const apiException = (error: any) => ga('send', formatApiException(error));

export const config = (c: { [k: string]: string }) => ga('set', c);

const useAnaytics = () => {
  const trackPage = useCallback(page, []);

  const trackEvent = useCallback(event, []);

  const trackException = useCallback(exception, []);

  const trackApiException = useCallback(apiException, []);

  const setConfig = useCallback(config, []);

  return {
    trackPage,
    trackEvent,
    trackException,
    trackApiException,
    setConfig,
  };
};

export default useAnaytics;
