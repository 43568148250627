import cn from 'classnames';
import * as React from 'react';

import ClosedBoxImage from '../../assets/icon-closed_box.png';
import OpenBoxImage from '../../assets/icon-openned_box.png';

import { SendBoxModeEnum } from '../../types/ui';

import styles from './SendBox.module.scss';

export interface ISendBoxProps {
  mode: SendBoxModeEnum;
  className?: string;
  imageClassName?: string;
  logo: string;
}

const SendBox: React.FC<ISendBoxProps> = ({ mode, className, imageClassName, logo }) => {
  const isOpened = React.useMemo(() => mode === SendBoxModeEnum.Open, [mode]);

  const boxImage = React.useMemo(() => {
    return isOpened ? OpenBoxImage : ClosedBoxImage;
  }, [isOpened]);

  return (
    <div className={cn(styles.boxContainer, className, isOpened ? styles.isOpened : styles.isClosed)}>
      <img src={boxImage} alt="" className={cn(styles.boxImage, imageClassName)} />
      <div className={cn(styles.logoWrapper, isOpened ? styles.isOpened : styles.isClosed)}>
        <div className={styles.sticker} />
        <div className={styles.logo} style={{ backgroundImage: `url('${logo}')` }} />
      </div>
    </div>
  );
};

export default SendBox;
